import setting3 from "../../assets/icons/setting-3.svg";
import cpuSetting from "../../assets/icons/cpu-setting.svg";
import code from "../../assets/icons/code.svg";

export const ServiceSection2 = () => {
  return (
    <div className="py-16 lg:py-20">
      <div className="ltext-left lg:text-center">
        <h2 className="font-bold text-[28px] lg:text-[48px] px-4 lg:px-80">
          MSI Suite Benefit for Customer
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 px-4 lg:px-20 mt-10 lg:mt-16">
          <div className="border-[1.5px] border-[#E9E9E9] p-4 lg:p-7">
            <img src={code} alt="" />
            <div className="text-left mt-4 lg:mt-7">
              <p className="font-bold text-base lg:text-2xl text-[#2C3131]">
                Build
              </p>

              <div className="text-[#626262] ml-4 mt-2 text-sm lg:text-base">
                <ul className="list-disc">
                  <li>
                    All you can develop! Flexibility un customizing/developing
                    features
                  </li>
                  <li>Easier integration with existing business</li>
                  <li>Access to advanced expertise, system & tools</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="border-[1.5px] border-[#E9E9E9] p-4 lg:p-7">
            <img src={cpuSetting} alt="" />
            <div className="text-left mt-4 lg:mt-7">
              <p className="font-bold text-base lg:text-2xl text-[#2C3131]">
                Operate
              </p>

              <div className="text-[#626262] ml-4 mt-2 text-sm lg:text-base">
                <ul className="list-disc">
                  <li>Lower initial investment</li>
                  <li>Risk sharing</li>
                  <li>Focus on core business</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="border-[1.5px] border-[#E9E9E9] p-4 lg:p-7">
            <img src={setting3} alt="" />
            <div className="text-left mt-4 lg:mt-7">
              <p className="font-bold text-base lg:text-2xl text-[#2C3131]">
                Manage
              </p>

              <div className="text-[#626262] ml-4 mt-2 text-sm lg:text-base">
                <ul className="list-disc">
                  <li>Optimize cost</li>
                  <li>Allows for innovation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
