import mobileAppDev from "../../assets/images/services/mobile-app-dev.png";
import salesApp from "../../assets/images/services/sales-app.png";
import profesionalDev from "../../assets/images/services/profesional-dev.png";
import outsourcing from "../../assets/images/services/outsourching.png";
import { Element, scroller, animateScroll as scroll } from "react-scroll";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ServiceSection1 = () => {
  const location = useLocation();

  const scrollTo = (val) => {
    scroller.scrollTo(val, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -50, // Scrolls to element + 50 pixels down the page
    });
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isHasQuery = params.get("service");
    if (isHasQuery) {
      scrollTo(isHasQuery);
    } else {
      scroll.scrollToTop();
    }
  }, [location]);

  return (
    <div className="pt-20">
      <div className="text-center">
        <h2 className="font-bold text-[32px] lg:text-[48px] px-4 lg:px-[26rem] tracking-[-0.04em] lg:tracking-[-0.02em] lg:leading-[56px] leading-[40px]">
          Managing Your Bussiness with Our Best Services.
        </h2>
        <Element
          name="mobile-apps"
          className="w-full block lg:flex items-center justify-between bg-[#FFFFFF] px-4 py-16 lg:px-20 lg:py-20 gap-20"
        >
          <div className="hidden lg:block w-1/2 h-max">
            <img
              width={"590px"}
              className="object-contain"
              src={mobileAppDev}
              alt=""
            />
          </div>
          <div className="w-full lg:w-1/2 text-left">
            <p className="font-semibold text-[28px] lg:text-[40px] text-[#2C3131] lg:pr-10 leading-[36px]  tracking-[-0.03em] lg:tracking-[-0.02em] lg:leading-[48px]">
              Mobile application development (Android, iOS)
            </p>
            <p className="font-medium mt-4 lg:mt-6 text-sm lg:text-base">
              We developed custom Android / iOS apps using best technologies
              with high-quality, cost-effective, and reliable mobile app for all
              types of companies, from small startups to large enterprises. Our
              team is ready to discuss with you to realize your idea.
            </p>
          </div>
          <div className="w-full lg:w-1/2 h-max mt-12 lg:mt-0 block lg:hidden">
            <img
              width={"590px"}
              className="object-contain"
              src={mobileAppDev}
              alt=""
            />
          </div>
        </Element>
        <Element
          name="payment-system"
          className="w-full block lg:flex items-center justify-between bg-[#FAFAFA] px-4 py-16 lg:px-20 lg:py-20 gap-20"
        >
          <div className="w-full lg:w-1/2 text-left">
            <p className="font-semibold text-[28px] lg:text-[40px] text-[#2C3131] leading-[36px] tracking-[-0.03em] lg:tracking-[-0.02em] lg:leading-[48px]">
              Sales App and Payment System
            </p>
            <p className="font-medium mt-4 lg:mt-6 lg:pr-10 text-sm lg:text-base">
              To make your marketing and business finance easier, we are ready
              to help you in making sales apps and payment systems with you.
              With the best technology and high quality, as well as
              cost-effective for all types of businesses. Our team is ready to
              solve the problems that exist in your company.
            </p>
          </div>
          <div className="w-full lg:w-1/2 h-max mt-12">
            <img
              width={"590px"}
              className="object-contain"
              src={salesApp}
              alt=""
            />
          </div>
        </Element>
        <Element
          name="web-design"
          className="w-full block lg:flex items-center justify-between bg-[#FFFFFF] px-4 py-16 lg:px-20 lg:py-20 gap-20"
        >
          <div className="hidden lg:block w-1/2 h-max">
            <img
              width={"590px"}
              className="object-contain"
              src={profesionalDev}
              alt=""
            />
          </div>
          <div className="w-full lg:w-1/2 text-left">
            <p className="font-semibold text-[28px] lg:text-[40px] text-[#2C3131] leading-[36px] tracking-[-0.03em] lg:tracking-[-0.02em] lg:leading-[48px]">
              Professional Web Design and Development
            </p>
            <p className="font-medium mt-4 lg:mt-6 text-sm lg:text-base">
              Develop highest quality website to enhance visibility and make it
              easier to manage your business such as company profile branding,
              e-commerce, and others. Our team is ready to discuss with you to
              realize your idea.
            </p>
          </div>
          <div className="w-full lg:w-1/2 h-max mt-12 lg:mt-0 block lg:hidden">
            <img
              width={"590px"}
              className="object-contain"
              src={profesionalDev}
              alt=""
            />
          </div>
        </Element>
        <Element
          name="outsourching"
          className="w-full block lg:flex items-center justify-between bg-[#FAFAFA] px-4 py-16 lg:px-20 lg:py-20 gap-20"
        >
          <div className="w-full lg:w-1/2 text-left">
            <p className="font-semibold text-[28px] lg:text-[40px] text-[#2C3131] leading-[36px] tracking-[-0.03em] lg:tracking-[-0.02em] lg:leading-[48px]">
              Outsourcing service either for development or application
              maintenance
            </p>
            <p className="font-medium mt-4 lg:mt-6 text-sm lg:text-base">
              We are ready to serve you for application development or
              maintenance. Because we have many great talents who can solve your
              ideas and problems. Contact us and do a consultation, then we will
              help you!
            </p>
          </div>
          <div className="w-full lg:w-1/2 h-max mt-12 lg:mt-0">
            <img
              width={"590px"}
              className="object-contain"
              src={outsourcing}
              alt=""
            />
          </div>
        </Element>
      </div>
    </div>
  );
};
