import { Footer } from "../components/Footer";
import { HomeSection1 } from "../components/Home/Section1";
import { HomeSection3 } from "../components/Home/Section3";
import { HomeSection4 } from "../components/Home/Section4";
import { HomeSection5 } from "../components/Home/Section5";
import { HomeSection6 } from "../components/Home/Section6";
import { HomeSection7 } from "../components/Home/Section7";
import { LetsStartProject } from "../components/LestStartProject";

export const Home = () => {
  return (
    <div>
      <HomeSection1 />

      <HomeSection3 />

      <HomeSection4 />
      <HomeSection5 />
      <HomeSection6 />
      <HomeSection7 />
      <div className="-mb-48 w-full flex justify-center relative bg-[#FAFAFA]">
        <div className="w-[80vw] z-50">
          <LetsStartProject />
        </div>
      </div>
      <div className="z-10">
        <div className="w-full h-[250px] bg-[#091315] relative z-20"></div>
        <Footer />
      </div>
    </div>
  );
};
