import { useState } from "react";
import phase1 from "../../assets/images/phase1.png";
import phase2 from "../../assets/images/phase2.png";
import arrowRight from "../../assets/icons/arrow-right.svg";

import prepareImgActive from "../../assets/icons/our-process/note-text-white.svg";
import prepareImgInactive from "../../assets/icons/our-process/note-text.svg";
import defineImgInactive from "../../assets/icons/our-process/search-normal.svg";
import defineImgActive from "../../assets/icons/our-process/search-normal-white.svg";
import designImgInactive from "../../assets/icons/our-process/designtools.svg";
import designImgActive from "../../assets/icons/our-process/designtools-white.svg";

import developImgInactive from "../../assets/icons/our-process/code-circle.svg";
import developImgActive from "../../assets/icons/our-process/code-circle-white.svg";
import polishImgInactive from "../../assets/icons/our-process/award.svg";
import polishImgActive from "../../assets/icons/our-process/award-white.svg";
import goLiveImgInactive from "../../assets/icons/our-process/play-circle.svg";
import goLiveImgActive from "../../assets/icons/our-process/play-circle-white.svg";
import supportImgInactive from "../../assets/icons/our-process/setting-3.svg";
import supportImgActive from "../../assets/icons/our-process/setting-3-white.svg";

export const HomeSection6 = () => {
  const [tabsection1, setTabSection1] = useState("prepare");
  const [tabsection2, setTabSection2] = useState("develop");

  const Phase1Content = () => {
    if (tabsection1 === "prepare") {
      return (
        <div className="p-6">
          <div>
            <p className="font-bold text-base lg:text-2xl mb-2">
              Assessment by Pre-Sales team
            </p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <p>
                <span className="mr-2">&#183;</span>Define what’s in and out of
                scope
              </p>
              <p>
                <span className="mr-2">&#183;</span>High Level Estimation of
                Time & Effort
              </p>
              <p>
                <span className="mr-2">&#183;</span>Define key Dependencies of
                the Solution
              </p>
            </div>
          </div>
          <div className="pt-6">
            <p className="font-bold text-base lg:text-2xl mb-2">Planning DnD</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Identity timeline for DnD Deliverables</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Assemble multidisciplinary team composition</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Workshop Agenda</p>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <p className="font-bold text-base lg:text-2xl mb-2">
              Internal Kick-Off
            </p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>List of Questions</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Concerns & Risks</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (tabsection1 === "define") {
      return (
        <div className="p-6">
          <div>
            <p className="font-bold text-base lg:text-2xl mb-2">Research</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Define Bussiness Objectives & Key Performance Indicators</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>
                  Identity the target users & pain points (Persona Analysis)
                </p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Competitive benchmarking</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>
                  Heuristic evaluation and sentiment analysis (if client has
                  existing app)
                </p>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <p className="font-bold text-base lg:text-2xl mb-2">
              Design Proposal
            </p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Persona - Limited to drivers only</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>UI look and feel options</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Wireframe to discuss main user journey (drivers only)</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (tabsection1 === "design") {
      return (
        <div className="p-6 flex items-center justify-around">
          <div>
            <p className="font-bold text-base lg:text-2xl mb-2">All Team</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Design (UI/UX Design)</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Engineering (Technical Design)</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Product (Story Writing)</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Quality Assurance (Test Planning)</p>
              </div>
            </div>
          </div>
          <img className="w-6 lg:w-10 mr-6 lg:mr-0" src={arrowRight} alt="" />
          <div>
            <p className="font-bold text-base lg:text-2xl mb-2">Product</p>
            <div className="text-[#626262] text-sm lg:text-base">
              <p>Release Plan</p>
            </div>
          </div>
        </div>
      );
    }
  };

  const Phase2Content = () => {
    if (tabsection2 === "develop") {
      return (
        <div className="p-6">
          <div>
            <p className="font-bold text-base lg:text-2xl mb-2">Action</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Code Strategy and Framework</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Coding</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Integration</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Functionality Testing</p>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <p className="font-bold text-base lg:text-2xl mb-2">Deliverables</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Working Product</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>List of Completed Stories</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>QA Report</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Budget Report</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Timesheets</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Project Progress Report</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (tabsection2 === "polish") {
      return (
        <div className="p-6">
          <div>
            <p className="font-bold text-base lg:text-2xl mb-2">Action</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Final Integration Test</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Performance & Security Testing</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Deployment Preparation</p>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <p className="font-bold text-base lg:text-2xl mb-2">Deliverables</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Load Testing Result and Recommendation</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Security Review Documents</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Final Acceptance Test Result</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (tabsection2 === "go_live") {
      return (
        <div className="p-6">
          <div>
            <p className="font-bold text-base lg:text-2xl mb-2">Action</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Launch and Launch Monitoring</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Handover Documentation and Training</p>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <p className="font-bold text-base lg:text-2xl mb-2">Deliverables</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>App Store Submission</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Backend Launch</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Handover Documentation</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (tabsection2 === "support") {
      return (
        <div className="p-6">
          <div>
            <p className="font-bold text-base lg:text-2xl mb-2">Action</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Monitoring and Reporting</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Maintenance and Improvements</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Dynamic Scaling</p>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <p className="font-bold text-base lg:text-2xl mb-2">Deliverables</p>
            <div className="text-[#626262] ml-1 text-sm lg:text-base">
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Weekly SLA Reports</p>
              </div>
              <div className="flex items-start">
                <span className="mr-2">&#183;</span>
                <p>Monthly user insight using App Analytics</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="h-fit">
      <div className="w-full h-full lg:flex items-start justify-between gap-20 bg-[#FAFAFA] px-4 lg:px-20 py-20">
        <div className="w-1/2 h-full hidden lg:block">
          <img className="h-[42rem] py-10" src={phase1} alt="" />
        </div>
        <div className="w-full lg:w-1/2 h-full text-left">
          <h2 className="font-semibold text-[#626262] text-lg">Our Process</h2>
          <p className="font-bold text-[28px] lg:text-[40px] mt-2">
            Phase 1 - Define & Design
          </p>
          <div className="bg-[#FFFFFF] w-full h-[30rem] mt-10 lg:mt-14">
            <div className="w-full grid grid-cols-3 text-center">
              <div
                className={`${
                  tabsection1 === "prepare"
                    ? "bg-[#2C3131] text-[#FFFFFF]"
                    : "bg-[#E9E9E9] text-[#626262]"
                } text-lg py-5 font-bold cursor-pointer transition-all duration-300 flex flex-col lg:flex-row items-center justify-center gap-2.5`}
                onClick={() => setTabSection1("prepare")}
              >
                <img
                  src={
                    tabsection1 === "prepare"
                      ? prepareImgActive
                      : prepareImgInactive
                  }
                  alt=""
                />
                <p>PREPARE</p>
              </div>
              <div
                className={`${
                  tabsection1 === "define"
                    ? "bg-[#2C3131] text-[#FFFFFF]"
                    : "bg-[#E9E9E9] text-[#626262]"
                } text-lg py-5 font-bold cursor-pointer transition-all duration-300 flex flex-col lg:flex-row items-center justify-center gap-2.5`}
                onClick={() => setTabSection1("define")}
              >
                <img
                  src={
                    tabsection1 === "define"
                      ? defineImgActive
                      : defineImgInactive
                  }
                  alt=""
                />
                <p>DEFINE</p>
              </div>
              <div
                className={`${
                  tabsection1 === "design"
                    ? "bg-[#2C3131] text-[#FFFFFF]"
                    : "bg-[#E9E9E9] text-[#626262]"
                } text-lg py-5 font-bold cursor-pointer transition-all duration-300 flex flex-col lg:flex-row items-center justify-center gap-2.5`}
                onClick={() => setTabSection1("design")}
              >
                <img
                  src={
                    tabsection1 === "design"
                      ? designImgActive
                      : designImgInactive
                  }
                  alt=""
                />
                <p>DEGISN</p>
              </div>
            </div>
            <Phase1Content />
          </div>
        </div>
        <div className="w-full h-full block lg:hidden">
          <img className="py-10" src={phase1} alt="" />
        </div>
      </div>
      <div className="w-full h-full lg:flex items-start justify-between gap-20 bg-[#FFFFFF] px-4 lg:px-20 mt-12 lg:mt-20">
        <div className="lg:w-[60%] h-full text-left">
          <h2 className="font-semibold text-[#626262] text-lg">Our Process</h2>
          <p className="font-bold text-[28px] lg:text-[40px] mt-2">
            Phase 2 - Implementation
          </p>
          <div className="bg-[#FAFAFA] w-full h-[30rem] mt-10 lg:mt-14">
            <div className="w-full grid grid-cols-4 text-center">
              <div
                className={`${
                  tabsection2 === "develop"
                    ? "bg-[#2C3131] text-[#FFFFFF]"
                    : "bg-[#E9E9E9] text-[#626262]"
                } text-lg py-5 font-bold cursor-pointer transition-all duration-300 flex flex-col lg:flex-row items-center justify-center gap-2`}
                onClick={() => setTabSection2("develop")}
              >
                <img
                  src={
                    tabsection2 === "develop"
                      ? developImgActive
                      : developImgInactive
                  }
                  alt=""
                />
                <p className="text-sm lg:text-lg">DEVELOP</p>
              </div>
              <div
                className={`${
                  tabsection2 === "polish"
                    ? "bg-[#2C3131] text-[#FFFFFF]"
                    : "bg-[#E9E9E9] text-[#626262]"
                } text-lg py-5 font-bold cursor-pointer transition-all duration-300 flex flex-col lg:flex-row items-center justify-center gap-2`}
                onClick={() => setTabSection2("polish")}
              >
                <img
                  src={
                    tabsection2 === "polish"
                      ? polishImgActive
                      : polishImgInactive
                  }
                  alt=""
                />
                <p className="text-sm lg:text-lg">POLISH</p>
              </div>
              <div
                className={`${
                  tabsection2 === "go_live"
                    ? "bg-[#2C3131] text-[#FFFFFF]"
                    : "bg-[#E9E9E9] text-[#626262]"
                } text-lg py-5 font-bold cursor-pointer transition-all duration-300 flex flex-col lg:flex-row items-center justify-center gap-2`}
                onClick={() => setTabSection2("go_live")}
              >
                <img
                  src={
                    tabsection2 === "go_live"
                      ? goLiveImgActive
                      : goLiveImgInactive
                  }
                  alt=""
                />
                <p className="text-sm lg:text-lg">GO LIVE</p>
              </div>
              <div
                className={`${
                  tabsection2 === "support"
                    ? "bg-[#2C3131] text-[#FFFFFF]"
                    : "bg-[#E9E9E9] text-[#626262]"
                } text-lg py-5 font-bold cursor-pointer transition-all duration-300 flex flex-col lg:flex-row items-center justify-center gap-2`}
                onClick={() => setTabSection2("support")}
              >
                <img
                  src={
                    tabsection2 === "support"
                      ? supportImgActive
                      : supportImgInactive
                  }
                  alt=""
                />
                <p className="text-sm lg:text-lg">SUPPORT</p>
              </div>
            </div>
            <Phase2Content />
          </div>
        </div>
        <div className="w-40% h-full">
          <img className="lg:h-[42rem] py-10" src={phase2} alt="" />
        </div>
      </div>
    </div>
  );
};
