import tickGreen from "../../assets/icons/tick-circle-green.svg";
import { Link } from "react-router-dom";

export const ProductPlanSection1 = () => {
  return (
    <div className="pt-20 h-fit pb-20 px-4 lg:px-20 z-[10]">
      <div className="w-full text-center">
        <h2
          className="font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#FE0000] to-[#FF8100] px-4 rounded-[20px] text-xs lg:text-base
      "
        >
          PRODUCT PLAN
        </h2>
        <h3 className="#2C3131 font-semibold text-[32px] lg:text-[40px] mt-4">
          What Can we Offer
        </h3>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-8 mt-10 lg:px-8">
        <div className="group col-span-1 py-5 border-[1.5px] border-[#E9E9E9] px-6 lg:px-10 hover:bg-[#FFFFFF] hover:shadow-card1 hover:border-none transition-all duration-100 h-[958px] relative z-10">
          <div className="flex justify-end w-full pt-5">
            <span className="bg-[#FF8100] text-white font-bold py-1 px-6 rounded-2xl">
              LOW
            </span>
          </div>
          <div className="text-left mt-12">
            <h5 className="font-semibold text-[#2C3131] text-base lg:text-2xl">
              Small Corporate
            </h5>
            <p className="text-[#2C3131] font-semibold text-[28px] lg:text-[40px] leading-[48px] tracking-[-0.03em] my-1 lg:my-2">
              Rp 50.000.000
            </p>
            <p className="text-[#A9A9A9] lg:text-lg font-medium text-sm">
              Valid for 2 years
            </p>
            <hr className="border-b border-[#E9E9E9] my-6 lg:my-8" />
          </div>
          <div>
            <div>
              <div className="flex">
                <img src={tickGreen} alt="" />
                <p className="ml-3.5 text-[#2C3131] font-semibold">
                  All You Can Develop
                </p>
              </div>
              <div className="ml-12 mt-2 text-[#626262]">
                <p>
                  <span className="mr-1">&#183;</span> Web & Mobile planning
                </p>
                <p>
                  <span className="mr-1">&#183;</span> UI/UX Design
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Sprint Planning
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Development
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Live Implementation
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Change Request
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Technical Support
                </p>
              </div>
            </div>
            <div>
              <div className="flex mt-6">
                <img src={tickGreen} alt="" />
                <p className="ml-3.5 text-[#2C3131] font-semibold">Resources</p>
              </div>
              <div className="ml-12 mt-2 text-[#626262]">
                <p>
                  <span className="mr-1">&#183;</span> 1 Project Manager
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 Business Analyst
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 UI/UX Designer
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 Software Engineer
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 Mobile Engineer
                </p>
              </div>
            </div>

            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131] font-semibold">
                32 Manhours / Month
              </p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131] font-semibold">
                Rp 5.000.000 Monthly Fee
              </p>
            </div>
          </div>
          <Link
            to={"/contact-us?plan=small"}
            className="w-full flex items-center justify-center h-fit mt-24"
          >
            <button className="border border-[#2C3131] mt-12 w-fit py-3 px-20 lg:px-24 rounded-[30px] font-bold text-lg group-hover:bg-[#2C3131] group-hover:text-white transition-all duration-150 lg:absolute lg:bottom-5">
              Get Started
            </button>
          </Link>
        </div>
        <div className="group col-span-1 py-5 border-[1.5px] border-[#E9E9E9] px-6 lg:px-10 hover:bg-[#FFFFFF] hover:shadow-card1 hover:border-none transition-all duration-100 h-[958px] relative">
          <div className="flex justify-end w-full pt-5">
            <span className="bg-[#4F81BD] text-white font-bold py-1 px-6 rounded-2xl">
              MID
            </span>
          </div>
          <div className="text-left mt-12">
            <h5 className="font-semibold text-[#2C3131] text-base lg:text-2xl">
              Medium Corporate
            </h5>
            <p className="text-[#2C3131] font-semibold text-[28px] lg:text-[40px] leading-[48px] tracking-[-0.03em] my-1 lg:my-2">
              Rp 80.000.000
            </p>
            <p className="text-[#A9A9A9] lg:text-lg font-medium text-sm">
              Valid for 2 years
            </p>
            <hr className="border-b border-[#E9E9E9] my-6 lg:my-8" />
          </div>
          <div>
            <div>
              <div className="flex">
                <img src={tickGreen} alt="" />
                <p className="ml-3.5 text-[#2C3131] font-semibold">
                  All You Can Develop
                </p>
              </div>
              <div className="ml-12 mt-2 text-[#626262]">
                <p>
                  <span className="mr-1">&#183;</span> Web & Mobile planning
                </p>
                <p>
                  <span className="mr-1">&#183;</span> UI/UX Design
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Sprint Planning
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Development
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Live Implementation
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Change Request
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Technical Support
                </p>
              </div>
            </div>
            <div>
              <div className="flex mt-6">
                <img src={tickGreen} alt="" />
                <p className="ml-3.5 text-[#2C3131] font-semibold">Resources</p>
              </div>
              <div className="ml-12 mt-2 text-[#626262]">
                <p>
                  <span className="mr-1">&#183;</span> 1 Project Manager
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 Business Analyst
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 UI/UX Designer
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 2 Software Engineer
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 Mobile Engineer
                </p>
              </div>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131] font-semibold">
                48 Manhours / Month
              </p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131] font-semibold">
                Rp 8.000.000 Monthly Fee
              </p>
            </div>
          </div>

          <Link
            to={"/contact-us?plan=medium"}
            className="w-full flex items-center justify-center h-fit mt-28"
          >
            <button className="border border-[#2C3131] w-fit py-3 px-20 lg:px-24 rounded-[30px] font-bold text-lg group-hover:bg-[#2C3131] group-hover:text-white transition-all duration-150 lg:absolute lg:bottom-5">
              Get Started
            </button>
          </Link>
        </div>
        <div className="group col-span-1 py-5 border-[1.5px] border-[#E9E9E9] px-6 lg:px-10 hover:bg-[#FFFFFF] hover:shadow-card1 hover:border-none transition-all duration-100 h-[958px] relative">
          <div className="flex justify-end w-full pt-5">
            <span className="bg-[#FE0000] text-white font-bold py-1 px-6 rounded-2xl">
              HIGH
            </span>
          </div>
          <div className="text-left mt-12">
            <h5 className="font-semibold text-[#2C3131] text-base lg:text-2xl">
              Enterprise
            </h5>
            <p className="text-[#2C3131] font-semibold text-[28px] lg:text-[40px] leading-[48px] tracking-[-0.03em] my-1 lg:my-2">
              Rp 150.000.000
            </p>
            <p className="text-[#A9A9A9] text-sm lg:text-lg font-medium">
              Valid for 3 years
            </p>
            <hr className="border-b border-[#E9E9E9] my-6 lg:my-8" />
          </div>
          <div>
            <div>
              <div className="flex">
                <img src={tickGreen} alt="" />
                <p className="ml-3.5 text-[#2C3131] font-semibold">
                  All You Can Develop
                </p>
              </div>
              <div className="ml-12 mt-2 text-[#626262]">
                <p>
                  <span className="mr-1">&#183;</span> Web & Mobile planning
                </p>
                <p>
                  <span className="mr-1">&#183;</span> UI/UX Design
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Sprint Planning
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Development
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Live Implementation
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Change Request
                </p>
                <p>
                  <span className="mr-1">&#183;</span> Technical Support
                </p>
              </div>
            </div>
            <div>
              <div className="flex mt-6">
                <img src={tickGreen} alt="" />
                <p className="ml-3.5 text-[#2C3131] font-semibold">Resources</p>
              </div>
              <div className="ml-12 mt-2 text-[#626262]">
                <p>
                  <span className="mr-1">&#183;</span> 1 Project Manager
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 Business Analyst
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 UI/UX Designer
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 Technical Lead
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 4 Software Engineer
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 2 Mobile Engineer
                </p>
                <p>
                  <span className="mr-1">&#183;</span> 1 Tester (QA)
                </p>
              </div>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131] font-semibold">
                64 Manhours / Month
              </p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131] font-semibold">
                Rp 15.000.000 Monthly Fee
              </p>
            </div>
          </div>
          <Link
            to={"/contact-us?plan=enterprise"}
            className="w-full flex items-center justify-center h-fit mt-20"
          >
            <button className="border border-[#2C3131] w-fit px-20 lg:px-24 py-3 rounded-[30px] font-bold text-lg group-hover:bg-[#2C3131] group-hover:text-white transition-all duration-150 lg:absolute lg:bottom-5">
              Get Started
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
