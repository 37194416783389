import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import msiIcon from "../assets/icons/icon-msi.svg";
import msiIconBlack from "../assets/icons/msi-icon-black.svg";
import menuIcon from "../assets/icons/humberger-menu.svg";
import menuIconBlack from "../assets/icons/humberger-menu-black.svg";

export const Header = () => {
  const { pathname } = useLocation();
  const [openSidebar, setOpenSidebar] = useState(false);

  const SidebarComponent = () => {
    return (
      <div className="block lg:hidden">
        <div
          className={`${
            pathname === "/" ? "bg-black" : "bg-transparent"
          } w-full h-full  opacity-75 fixed`}
        ></div>
        <div
          className={`${
            openSidebar
              ? "animate-sidebar-animation w-full max-h-[75vh] duration-1000"
              : "animate-sidebar-animation-close w-full max-h-0 duration-1000"
          } bg-white fixed right-0 top-0 h-full shadow-xl z-[999]`}
        >
          <div className="flex justify-between items-center h-20">
            <Link to="/">
              <div className="flex items-center gap-4 pl-8">
                <img
                  className="h-10 w-10 lg:h-12 lg:w-12"
                  src={msiIconBlack}
                  alt="icon-msi"
                />
                <div
                  className={pathname === "/" ? "text-white" : "text-[#2C3131]"}
                >
                  <p className="font-bold text-xl leading-5 lg:leading-8 lg:text-2xl text-[#2C3131]">
                    MSI SUITE
                  </p>
                  <p className="text-[#A9A9A9] font-medium text-xs leading-4 lg:text-sm tracking-[-0.03em] lg:leading-5">
                    All You Can Develop!
                  </p>
                </div>
              </div>
            </Link>

            <div className="pr-4">
              <svg
                width="14"
                height="14"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setOpenSidebar(false)}
              >
                <path
                  d="M1.00098 1L15 14.9991"
                  stroke="#2C3131"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.999964 14.9991L14.999 1"
                  stroke="#2C3131"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="text-[#A9A9A9]">
            <Link to="/about">
              <p
                className={`${
                  pathname === "/about"
                    ? "font-bold text-[#2C3131]"
                    : "font-semibold "
                } uppercase cursor-pointer transition-colors duration-300 border-b border-[#DFE8F6] pt-10 pb-6 text-center`}
              >
                About Us
              </p>
            </Link>
            <Link to="/service">
              <p
                className={`${
                  pathname === "/service"
                    ? "font-bold text-[#2C3131]"
                    : "font-semibold "
                } uppercase cursor-pointer transition-colors duration-300 border-b border-[#DFE8F6] py-6 text-center`}
              >
                Our SERVICE
              </p>
            </Link>
            <Link to="/product">
              <p
                className={`${
                  pathname === "/product"
                    ? "font-bold text-[#2C3131]"
                    : "font-semibold "
                } uppercase cursor-pointer transition-colors duration-300 border-b border-[#DFE8F6] py-6 text-center`}
              >
                Product Plan
              </p>
            </Link>
            <Link to="/portofolio">
              <p
                className={`${
                  pathname === "/portofolio"
                    ? "font-bold text-[#2C3131]"
                    : "font-semibold "
                } uppercase cursor-pointer transition-colors duration-300 border-b border-[#DFE8F6] py-6 text-center`}
              >
                Portfolio
              </p>
            </Link>
            <Link to="/contact-us">
              <p
                className={`${
                  pathname === "/contact-us"
                    ? "font-bold text-[#2C3131]"
                    : "font-semibold "
                } uppercase cursor-pointer transition-colors duration-300 py-6 text-center`}
              >
                Contact Us
              </p>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {openSidebar && <SidebarComponent />}
      <div
        className={`${
          pathname === "/" ? "bg-[#091315]" : "bg-[#FFFFFF]"
        } w-full h-20 lg:h-24 flex justify-between items-center transition-all duration-300`}
      >
        <Link to="/">
          <div className="flex items-center pl-8 gap-4">
            <div>
              <img
                className="h-10 w-10 lg:h-12 lg:w-12"
                src={pathname === "/" ? msiIcon : msiIconBlack}
                alt="icon-msi"
              />
            </div>
            <div className={pathname === "/" ? "text-white" : "text-[#2C3131]"}>
              <p className="font-bold text-xl leading-5 lg:leading-8 lg:text-2xl">
                MSI SUITE
              </p>
              <p className="text-[#A9A9A9] font-medium text-xs leading-4 lg:text-sm tracking-[-0.03em] lg:leading-5">
                All You Can Develop!
              </p>
            </div>
          </div>
        </Link>
        <div
          className="pr-4 block lg:hidden"
          onClick={() => setOpenSidebar(true)}
        >
          <img src={pathname === "/" ? menuIcon : menuIconBlack} alt="" />
        </div>

        <div className="text-[#A9A9A9] hidden lg:flex items-center gap-6 pr-20 transition-colors duration-300 ">
          <Link to="/about">
            <p
              className={`${
                pathname === "/about"
                  ? "font-bold text-[#2C3131]"
                  : "font-semibold "
              } uppercase cursor-pointer transition-colors duration-300`}
            >
              About Us
            </p>
          </Link>
          <Link to="/service">
            <p
              className={`${
                pathname === "/service"
                  ? "font-bold text-[#2C3131]"
                  : "font-semibold "
              } uppercase cursor-pointer transition-colors duration-300`}
            >
              Our SERVICE
            </p>
          </Link>
          <Link to="/product">
            <p
              className={`${
                pathname === "/product"
                  ? "font-bold text-[#2C3131]"
                  : "font-semibold "
              } uppercase cursor-pointer transition-colors duration-300`}
            >
              Product Plan
            </p>
          </Link>
          <Link to="/portofolio">
            <p
              className={`${
                pathname === "/portofolio"
                  ? "font-bold text-[#2C3131]"
                  : "font-semibold "
              } uppercase cursor-pointer transition-colors duration-300`}
            >
              Portfolio
            </p>
          </Link>
          <Link to="/contact-us">
            <p
              className={`${
                pathname === "/contact-us"
                  ? "font-bold text-[#2C3131]"
                  : "font-semibold "
              } uppercase cursor-pointer transition-colors duration-300`}
            >
              Contact Us
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};
