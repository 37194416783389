import { Footer } from "../components/Footer";
import { LetsStartProject } from "../components/LestStartProject";
import { ServiceSection1 } from "../components/Service/Section1";
import { ServiceSection2 } from "../components/Service/Section2";

export const Service = () => {
  return (
    <div>
      <ServiceSection1 />
      <ServiceSection2 />
      <div className="-mb-48 w-full flex justify-center z-20 relative">
        <div className="w-[80vw]">
          <LetsStartProject />
        </div>
      </div>
      <div className="z-10 ">
        <div className="w-full h-[250px] bg-[#091315] relative"></div>
        <Footer />
      </div>
    </div>
  );
};
