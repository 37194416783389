import { Footer } from "../components/Footer";
import { LetsStartProject } from "../components/LestStartProject";
import { PortofolioSection1 } from "../components/Portofolio/Section1";
import { PortofolioSection2 } from "../components/Portofolio/Section2";

export const Portofolio = () => {
  return (
    <div>
      <PortofolioSection1 />
      <PortofolioSection2 />
      <div className="-mb-48 w-full flex justify-center relative bg-[#FAFAFA]">
        <div className="w-[80vw] z-50">
          <LetsStartProject />
        </div>
      </div>
      <div className="z-10">
        <div className="w-full h-[250px] bg-[#091315] relative z-20"></div>
        <Footer />
      </div>
    </div>
  );
};
