import { HomeSection2 } from "./Section2";
import homeHero from "../../assets/images/img-hero-home.png";

export const HomeSection1 = () => {
  return (
    <>
      <div className="bg-home-1 bg-center bg-cover bg-no-repeat lg:h-screen pb-40 lg:pb-0">
        <div className="block lg:flex items-end pl-4 pt-20 lg:px-12 lg:pt-28">
          <div className="w-full lg:w-1/2 text-left">
            <h5 className="text-[#A9A9A9] font-bold text-xs lg:text-lg">
              SOFTWARE DEVELOPMENT SUBSCRIPTION PLAN
            </h5>
            <h1 className="text-white font-bold text-[32px] lg:text-[48px] py-4 lg:py-0">
              MSI Suite Provides with Premium Quality IT Solution
            </h1>
          </div>
          <div className="w-full lg:w-1/2">
            <p className="text-[#A9A9A9] text-left pr-4 lg:pr-16 font-medium lg:pb-4 text-sm lg:text-base">
              MSI was established in early 2014 by experienced software
              engineers who have been working in software development field in
              various software development areas such as Mobile Application
              (Android, iOS), Website Development, and management system, and
              many more.
            </p>
          </div>
        </div>
        <div className="w-full flex justify-center mt-20">
          <img className="w-[90%]" src={homeHero} alt="" />
        </div>
      </div>
      <div className="lg:pt-[25rem]">
        <HomeSection2 />
      </div>
    </>
  );
};
