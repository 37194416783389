import indonesiaimg from "../../assets/images/indonesia.png";
import indonesiaMobileImg from "../../assets/images/indonesia-mobile.png";

export const AboutSection1 = () => {
  const openMaps = () => {
    window.open("https://goo.gl/maps/jFHBpBu6YZm1XHkx5");
  };

  return (
    <div className="py-20 text-center">
      <h2 className="font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#FE0000] to-[#FF8100] text-xs lg:text-base">
        ABOUT US
      </h2>
      <div>
        <p className="font-bold text-[32px] lg:text-[48px] text-[#2C3131] px-4 lg:px-0">
          MSI Suite Provides Premium Quality IT Solution
        </p>
        <p className="text-[#A9A9A9] font-medium px-4 lg:px-80 text-center text-sm lg:text-base mt-4 lg:mt-0">
          MSI was established in early 2014 by experienced software engineers
          who have been working in software development field in various
          software development areas such as Mobile Application (Android, iOS),
          Website Development, and management system, and many more.
        </p>
        <div className="relative mt-16 block lg:hidden">
          <img className="object-contain" src={indonesiaMobileImg} alt="" />
          <span
            className="bg-[#FFFFFF] text-[#2C3131] font-medium absolute bottom-[9rem] left-[5.5rem] w-[219px] text-left shadow-address px-4 py-3 cursor-pointer hover:underline"
            onClick={openMaps}
          >
            Jl. Raya Kby. Lama No.42, RW.10, Grogol Sel., Kec. Kby. Lama, Kota
            Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12220
          </span>
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-pulse absolute bottom-[7.5rem] left-[5rem]"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.5 8.31776C0.5 3.71789 4.34388 0 8.99345 0C13.6561 0 17.5 3.71789 17.5 8.31776C17.5 10.6357 16.657 12.7876 15.2695 14.6116C13.7388 16.6235 11.8522 18.3765 9.72854 19.7524C9.24251 20.0704 8.80387 20.0944 8.27045 19.7524C6.13474 18.3765 4.24809 16.6235 2.7305 14.6116C1.34198 12.7876 0.5 10.6357 0.5 8.31776ZM6.19423 8.57675C6.19423 10.1177 7.45166 11.3297 8.99345 11.3297C10.5362 11.3297 11.8058 10.1177 11.8058 8.57675C11.8058 7.0478 10.5362 5.77683 8.99345 5.77683C7.45166 5.77683 6.19423 7.0478 6.19423 8.57675Z"
              fill="#FF8100"
            />
          </svg>
        </div>
        <div className="hidden lg:block w-full relative mt-10">
          <img className="object-contain" src={indonesiaimg} alt="" />
          <span
            className="bg-[#FFFFFF] text-[#2C3131] font-medium absolute bottom-[10.5rem] left-[23rem] w-[322px] text-left shadow-address px-4 py-3 cursor-pointer hover:underline"
            onClick={openMaps}
          >
            Jl. Raya Kby. Lama No.42, RW.10, Grogol Sel., Kec. Kby. Lama, Kota
            Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12220
          </span>
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-pulse absolute bottom-[9rem] left-[22.5rem]"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.5 8.31776C0.5 3.71789 4.34388 0 8.99345 0C13.6561 0 17.5 3.71789 17.5 8.31776C17.5 10.6357 16.657 12.7876 15.2695 14.6116C13.7388 16.6235 11.8522 18.3765 9.72854 19.7524C9.24251 20.0704 8.80387 20.0944 8.27045 19.7524C6.13474 18.3765 4.24809 16.6235 2.7305 14.6116C1.34198 12.7876 0.5 10.6357 0.5 8.31776ZM6.19423 8.57675C6.19423 10.1177 7.45166 11.3297 8.99345 11.3297C10.5362 11.3297 11.8058 10.1177 11.8058 8.57675C11.8058 7.0478 10.5362 5.77683 8.99345 5.77683C7.45166 5.77683 6.19423 7.0478 6.19423 8.57675Z"
              fill="#FF8100"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
