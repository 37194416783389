import msiIcon from "../assets/icons/icon-msi.svg";
import toTop from "../assets/icons/to-top.svg";
import youtube from "../assets/icons/youtube.svg";
import instagram from "../assets/icons/instagram.svg";
import linkedin from "../assets/icons/linkedin.svg";
import mail from "../assets/icons/mail.svg";
import phone from "../assets/icons/phone.svg";
import location from "../assets/icons/location.svg";

import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
const scrollToTop = () => {
  scroll.scrollToTop();
};

const mailToOpen = () => {
  window.open("mailto:Wedianto@msi-digital.com");
};

export const Footer = () => {
  return (
    <div className="bg-[#091315] lg:h-96 pt-16 lg:pt-20 lg:px-20 relative">
      <div className="pb-40 px-4 block lg:hidden">
        <div className="w-[10%]">
          <img src={msiIcon} alt="" />
        </div>
        <div className="text-left w-full mt-12">
          <p className="text-[#A9A9A9] font-semibold">OUR SERVICE</p>
          <Link to="/service">
            <p className="text-[#FFFFFF] mt-4">Mobile App Development</p>
          </Link>
          <Link to="/service">
            <p className="text-[#FFFFFF] mt-3">Payment System Development</p>
          </Link>
          <Link to="/service">
            <p className="text-[#FFFFFF] mt-3">Web Design fo Interner & SIte</p>
          </Link>
          <Link to="/service">
            <p className="text-[#FFFFFF] mt-3">Outsourching</p>
          </Link>
        </div>
        <div className="flex mt-10">
          <div className="text-left w-1/2">
            <p className="text-[#A9A9A9] font-semibold">PRODUCT PLAN</p>
            <Link to="/product">
              <p className="text-[#FFFFFF] mt-4">Small Corporate</p>
            </Link>
            <Link to="/product">
              <p className="text-[#FFFFFF] mt-3">Medium Corporate</p>
            </Link>
            <Link to="/product">
              <p className="text-[#FFFFFF] mt-3">Enterprise</p>
            </Link>
          </div>
          <div className="text-left">
            <p className="text-[#A9A9A9] font-semibold">ABOUT US</p>
            <Link to="/about">
              <p className="text-[#FFFFFF] mt-4">Who We Are</p>
            </Link>
            <Link to="/about">
              <p className="text-[#FFFFFF] mt-3">Business Competence</p>
            </Link>
            <Link to="/about">
              <p className="text-[#FFFFFF] mt-3">Our Digital Solution</p>
            </Link>
          </div>
        </div>
        <div className="text-left w-full mt-10">
          <p className="text-[#A9A9A9] font-semibold">GET IN TOUCH</p>
          <div className="flex items-center mt-4">
            <img className="h-6 w-6" src={mail} alt="" />
            <p
              className="text-[#FFFFFF] ml-4 cursor-pointer"
              onClick={mailToOpen}
            >
              Wedianto@msi-digital.com
            </p>
          </div>
          <div className="flex items-center mt-3">
            <img className="h-6 w-6" src={phone} alt="" />

            <p
              className="text-[#FFFFFF] ml-4 cursor-pointer"
              onClick={() => window.open(`https://wa.me/628561026209`)}
            >
              (+62) 856-1026-209
            </p>
          </div>
          <div className="flex items-start mt-3">
            <img className="h-6 w-6" src={location} alt="" />
            <div>
              <p
                className="text-[#FFFFFF] ml-4 cursor-pointer"
                onClick={() =>
                  window.open("https://goo.gl/maps/jFHBpBu6YZm1XHkx5")
                }
              >
                Jl. Raya Kby. Lama No.42, RW.10,
              </p>
              <p
                className="text-[#FFFFFF] ml-4 cursor-pointer"
                onClick={() =>
                  window.open("https://goo.gl/maps/jFHBpBu6YZm1XHkx5")
                }
              >
                Grogol Sel., Kec. Kby. Lama,
              </p>
              <p
                className="text-[#FFFFFF] ml-4 cursor-pointer"
                onClick={() =>
                  window.open("https://goo.gl/maps/jFHBpBu6YZm1XHkx5")
                }
              >
                Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12220
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex items-start justify-start w-full gap-8">
        <div className="w-[10%]">
          <img src={msiIcon} alt="" />
        </div>

        <div className="text-left w-[20%]">
          <p className="text-[#A9A9A9] font-semibold">OUR SERVICE</p>
          <Link to="/service">
            <p className="text-[#FFFFFF] mt-6">Mobile App Development</p>
          </Link>
          <Link to="/service">
            <p className="text-[#FFFFFF] mt-4">Payment System Development</p>
          </Link>
          <Link to="/service">
            <p className="text-[#FFFFFF] mt-4">Web Design fo Interner & SIte</p>
          </Link>
          <Link to="/service">
            <p className="text-[#FFFFFF] mt-4">Outsourching</p>
          </Link>
        </div>
        <div className="text-left w-[20%] pl-6">
          <p className="text-[#A9A9A9] font-semibold">PRODUCT PLAN</p>
          <Link to="/product">
            <p className="text-[#FFFFFF] mt-6">Small Corporate</p>
          </Link>
          <Link to="/product">
            <p className="text-[#FFFFFF] mt-4">Medium Corporate</p>
          </Link>
          <Link to="/product">
            <p className="text-[#FFFFFF] mt-4">Enterprise</p>
          </Link>
        </div>
        <div className="text-left w-[20%]">
          <p className="text-[#A9A9A9] font-semibold">ABOUT US</p>
          <Link to="/about">
            <p className="text-[#FFFFFF] mt-6">Who We Are</p>
          </Link>
          <Link to="/about">
            <p className="text-[#FFFFFF] mt-4">Business Competence</p>
          </Link>
          <Link to="/about">
            <p className="text-[#FFFFFF] mt-4">Our Digital Solution</p>
          </Link>
        </div>
        <div className="text-left w-[30%]">
          <p className="text-[#A9A9A9] font-semibold">GET IN TOUCH</p>
          <div className="flex items-center mt-6">
            <img className="h-6 w-6" src={mail} alt="" />
            <p
              className="text-[#FFFFFF] ml-4 cursor-pointer"
              onClick={mailToOpen}
            >
              Wedianto@msi-digital.com
            </p>
          </div>
          <div className="flex items-center mt-4">
            <img className="h-6 w-6" src={phone} alt="" />

            <p
              className="text-[#FFFFFF] ml-4 cursor-pointer"
              onClick={() => window.open(`https://wa.me/628561026209`)}
            >
              (+62) 856-1026-209
            </p>
          </div>
          <div className="flex items-start mt-4">
            <img className="h-6 w-6" src={location} alt="" />
            <p
              className="text-[#FFFFFF] ml-4 cursor-pointer"
              onClick={() =>
                window.open("https://goo.gl/maps/jFHBpBu6YZm1XHkx5")
              }
            >
              Jl. Raya Kby. Lama No.42, RW.10, Grogol Sel., Kec. Kby. Lama, Kota
              Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12220
            </p>
          </div>
        </div>
      </div>
      <div className="absolute bottom-10 lg:bottom-0 right-0">
        <div className="flex flex-col bg-[#FFFFFF] items-center justify-around h-52">
          <img className="h-6 w-6" src={instagram} alt="" />
          <img className="h-6 w-6" src={linkedin} alt="" />
          <img className="h-6 w-6" src={youtube} alt="" />
        </div>
        <img
          className="h-12 w-12 cursor-pointer"
          src={toTop}
          alt=""
          onClick={scrollToTop}
        />
      </div>
      <div className="text-[#A9A9A9] absolute bottom-0 border-t border-[#626262] w-[85%] px-4 py-6 lg:px-6 lg:py-6">
        <div className="block lg:flex items-center">
          <p className="text-sm lg:text-base">
            ©2021 MSI Group LTD. All rights reserved
          </p>
          <span className="mx-4 hidden lg:block">&bull;</span>
          <p className="text-sm lg:text-base mt-2 lg:mt-0">
            Terms and Conditions
          </p>
        </div>
      </div>
    </div>
  );
};
