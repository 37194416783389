import benefirForCustomerImg from "../../assets/images/benefit-for-customer.png";
import setting3 from "../../assets/icons/setting-3.svg";
import cpuSetting from "../../assets/icons/cpu-setting.svg";
import code from "../../assets/icons/code.svg";

export const HomeSection4 = () => {
  return (
    <div className="h-fit lg:h-fit px-4 lg:px-20 pb-16 lg:pb-10 pt-20 lg:pt-10 bg-[#FAFAFA]">
      <div className="lg:flex w-full">
        <div className="lg:w-1/2">
          <h2 className="font-bold text-[28px] lg:text-[40px] text-left text-[#2C3131]">
            MSI Suite Benefit for Customer
          </h2>
          <div>
            <div className="flex items-start mt-16">
              <div className="w-[28px]">
                <img className="object-contain" src={code} alt="" />
              </div>
              <div className="text-left ml-4 lg:ml-6">
                <h3 className="font-bold text-base lg:text-2xl">Build</h3>
                <ul className="list-disc ml-4 text-[#626262] mt-2 text-sm lg:text-base">
                  <li>
                    All you can develop! Flexibility un customizing/developing
                    features
                  </li>
                  <li>Easier integration with existing business</li>
                  <li>Access to advanced expertise, system & tools</li>
                </ul>
              </div>
            </div>
            <div className="flex items-start mt-6">
              <div className="w-[28px]">
                <img
                  className="object-contain w-[28px]"
                  src={cpuSetting}
                  alt=""
                />
              </div>
              <div className="text-left ml-4 lg:ml-6 w-full">
                <h3 className="font-bold text-base lg:text-2xl">Operate</h3>
                <ul className="list-disc ml-4 text-[#626262] mt-2 text-sm lg:text-base">
                  <li>Lower initial investment</li>
                  <li>Risk sharing</li>
                  <li>Focus on core business</li>
                </ul>
              </div>
            </div>
            <div className="flex items-start mt-6">
              <div className="w-[28px]">
                <img
                  className="object-contain w-[28px]"
                  src={setting3}
                  alt=""
                />
              </div>
              <div className="text-left ml-4 lg:ml-6 w-full">
                <h3 className="font-bold text-base lg:text-2xl">Manage</h3>
                <ul className="list-disc ml-4 text-[#626262] mt-2 text-sm lg:text-base">
                  <li>Optimize cost</li>
                  <li>Allows for innovation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-6 lg:mt-0 lg:w-1/2 flex lg:flex-col justify-center">
          <img className="object-contain" src={benefirForCustomerImg} alt="" />
        </div>
      </div>
    </div>
  );
};
