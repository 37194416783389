import client1 from "../../assets/icons/features-client/features-client-1.svg";
import client2 from "../../assets/icons/features-client/features-client-2.svg";
import client3 from "../../assets/icons/features-client/features-client-3.svg";
import client4 from "../../assets/icons/features-client/features-client-4.svg";

export const HomeSection2 = () => {
  return (
    <div className="h-fit pt-32 pb-32 px-4 lg:px-20 text-center">
      <h2 className="font-semibold text-[#A9A9A9] text-lg">FEATURES CLIENT</h2>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 mt-10">
        <div className="bg-[#FAFAFA] col-span-1 flex items-center justify-center h-16 lg:h-36">
          <img className="w-14 lg:w-40" src={client1} alt="" />
        </div>
        <div className="bg-[#FAFAFA] col-span-1 flex items-center justify-center h-16 lg:h-36">
          <img className="w-14 lg:w-24" src={client2} alt="" />
        </div>
        <div className="bg-[#FAFAFA] col-span-1 flex items-center justify-center h-16 lg:h-36">
          <img className="w-20 lg:w-40" src={client3} alt="" />
        </div>
        <div className="bg-[#FAFAFA] col-span-1 flex items-center justify-center h-16 lg:h-36">
          <img className="w-36 lg:w-60" src={client4} alt="" />
        </div>
      </div>
    </div>
  );
};
