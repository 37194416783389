import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import androidStudio from "../../assets/icons/tech/android-studio.png";
import inkScape from "../../assets/icons/tech/ink-scape.png";
import apache from "../../assets/icons/tech/apache.png";
import oracle from "../../assets/icons/tech/oracle.png";
import xCode from "../../assets/icons/tech/x-code.png";
import marvel from "../../assets/icons/tech/marvel.png";
import mySql from "../../assets/icons/tech/my-sql.png";
import laravel from "../../assets/icons/tech/laravel.png";
import figma from "../../assets/icons/tech/figma.png";
import kotlin from "../../assets/icons/tech/kotlin.png";
import firebase from "../../assets/icons/tech/firebase.png";
import flutter from "../../assets/icons/tech/flutter.png";
import java from "../../assets/icons/tech/java.png";
import yiiFramework from "../../assets/icons/tech/yii-framework.png";
import ionic from "../../assets/icons/tech/ionic.png";
import mongo from "../../assets/icons/tech/mongo.png";
import mtcna from "../../assets/icons/tech/mtcna.png";
import nodejs from "../../assets/icons/tech/nodejs.png";
import react from "../../assets/icons/tech/react.png";
import swift from "../../assets/icons/tech/swift.png";

export const HomeSection7 = () => {
  const [dataImage, setDataImage] = useState([
    { name: "ANDROID STUDIO", img: androidStudio },
    { name: "INKSCAPE", img: inkScape },
    { name: "APACHE", img: apache },
    { name: "ORACLE", img: oracle },
    { name: "XCODE", img: xCode },
    { name: "MARVELAPP", img: marvel },
    { name: "MYSQL", img: mySql },
    { name: "LARAVEL", img: laravel },
    { name: "FIGMA", img: figma },
    { name: "KOTLIN", img: kotlin },
    { name: "FIREBASE", img: firebase },
    { name: "FLUTTER", img: flutter },
    { name: "JAVA", img: java },
    { name: "YII FRAMEWORK", img: yiiFramework },
    { name: "IONIC", img: ionic },
    { name: "REACT JS", img: react },
    { name: "MONGO DB", img: mongo },
    { name: "NODE JS", img: nodejs },
    { name: "SWIFT", img: swift },
    { name: "MIKROTIK MTCNA", img: mtcna },
  ]);

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // className: "center",
          slidesToShow: 2,
          slidesToScroll: 1,
          // rows: 2,
          // slidesPerRow: 2,
          // centerPadding: "60px",
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="lg:h-fit lg:pb-20 pt-20 bg-[#FAFAFA] lg:px-32 max-w-full text-center">
      <h2 className="font-semibold text-[#2C3131] text-[28px] lg:text-[40px] pl-4 lg:pl-0">
        Our Tools & Technology
      </h2>
      <div className="py-10 w-full px-2">
        <Slider {...settings}>
          {dataImage.map((el, idx) => {
            return (
              <div key={idx}>
                <div className="h-40 w-40 lg:h-60 lg:w-60 bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center mt-10">
                  <img
                    className={
                      el.name === "MARVELAPP" ||
                      el.name === "NODE JS" ||
                      el.name === "MIKROTIK MTCNA"
                        ? "w-32"
                        : "h-20 lg:h-28"
                    }
                    src={el.img}
                    alt=""
                  />
                </div>
                <p className="w-40 lg:w-60 text-center text-sm lg:text-lg text-[#2C3131] font-bold mt-4 lg:mt-6">
                  {el.name}
                </p>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
