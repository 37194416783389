import managingBussinesImg from "../../assets/images/managing-bussines.png";
import mobileIcon from "../../assets/icons/mobile.svg";
import cardIcon from "../../assets/icons/cards.svg";
import monitorIcon from "../../assets/icons/monitor.svg";
import userIcon from "../../assets/icons/profile-2user.svg";
import { Link } from "react-router-dom";

export const HomeSection3 = () => {
  return (
    <div className="p-6 lg:p-20">
      <div className="flex w-full gap-5">
        <div className="hidden lg:flex flex-col justify-center w-1/2">
          <img
            className="h-[36rem] object-contain"
            src={managingBussinesImg}
            alt=""
          />
        </div>
        <div className="w-full lg:w-1/2">
          <h2 className="font-bold text-[28px] lg:text-[40px] text-left text-[#2C3131] tracking-[-0.02em] leading-[48px] lg:pr-20">
            Managing Your Bussiness with Our Best Service.
          </h2>
          <p className="text-[#626262] font-semibold text-sm lg:text-lg text-left mt-2">
            BUT NOT LIMITED TO :
          </p>
          <div className="grid grid-cols-2 mt-12 gap-4">
            <div className="border border-[#E9E9E9] col-span-1 p-4 lg:p-8 text-left">
              <img src={mobileIcon} alt="" />
              <p className="text-[#2C3131] text-base lg:text-lg font-semibold tracking-[-0.03em] pt-6 lg:pr-4">
                Mobile application development (Android, iOS)
              </p>
            </div>
            <div className="border border-[#E9E9E9] col-span-1 p-4 lg:p-8 text-left">
              <img src={cardIcon} alt="" />
              <p className="text-[#2C3131] text-base lg:text-lg font-semibold tracking-[-0.03em] pt-6 lg:pr-4">
                Sales App and Payment System
              </p>
            </div>
            <div className="border border-[#E9E9E9] col-span-1 p-4 lg:p-8 text-left">
              <img src={monitorIcon} alt="" />
              <p className="text-[#2C3131] text-base lg:text-lg font-semibold tracking-[-0.03em] pt-6 lg:pr-4">
                Professional Web Design and Development
              </p>
            </div>
            <div className="border border-[#E9E9E9] col-span-1 p-4 lg:p-8 text-left">
              <img src={userIcon} alt="" />
              <p className="text-[#2C3131] text-base lg:text-lg font-semibold tracking-[-0.03em] pt-6 lg:pr-4">
                Outsourcing service either for development or application
                maintenance. etc
              </p>
            </div>
          </div>
          <div className="w-full flex justify-start mt-8">
            <Link to={"/about"}>
              <button className="bg-[#FF8100] w-fit px-10 py-4 rounded-[28px] font-bold text-white">
                Learn More
              </button>
            </Link>
          </div>
          <div className="block lg:hidden w-full mt-16">
            <img className="object-contain" src={managingBussinesImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
