import androidStudio from "../../assets/icons/tech/android-studio.png";
import inkScape from "../../assets/icons/tech/ink-scape.png";
import apache from "../../assets/icons/tech/apache.png";
import oracle from "../../assets/icons/tech/oracle.png";
import xCode from "../../assets/icons/tech/x-code.png";
import marvel from "../../assets/icons/tech/marvel.png";
import mySql from "../../assets/icons/tech/my-sql.png";
import laravel from "../../assets/icons/tech/laravel.png";
import figma from "../../assets/icons/tech/figma.png";
import kotlin from "../../assets/icons/tech/kotlin.png";
import firebase from "../../assets/icons/tech/firebase.png";
import flutter from "../../assets/icons/tech/flutter.png";
import java from "../../assets/icons/tech/java.png";
import yiiFramework from "../../assets/icons/tech/yii-framework.png";
import ionic from "../../assets/icons/tech/ionic.png";
import mongo from "../../assets/icons/tech/mongo.png";
import mtcna from "../../assets/icons/tech/mtcna.png";
import nodejs from "../../assets/icons/tech/nodejs.png";
import react from "../../assets/icons/tech/react.png";
import swift from "../../assets/icons/tech/swift.png";

export const PortofolioSection2 = () => {
  return (
    <div className="px-4 lg:px-20 py-16 lg:py-20 bg-[#FAFAFA] text-center">
      <h2 className="font-semibold text-[#2C3131] text-[28px] lg:text-[40px]">
        Our Tools & Technology
      </h2>
      <div className="grid grid-cols-2 lg:grid-cols-5 gap-6 lg:gap-16 mt-10 lg:mt-16 justify-items-center">
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px]">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img
              className="h-[84.67px] lg:h-[100px]"
              src={androidStudio}
              alt=""
            />
          </div>
          <p className="text-sm lg:text-lg text-[#2C3131] font-bold mt-4 lg:mt-6">
            ANDROID STUDIO
          </p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px]">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[84.67px] lg:h-[100px]" src={inkScape} alt="" />
          </div>
          <p className="text-sm lg:text-lg text-[#2C3131] font-bold mt-4 lg:mt-6">
            INKSCAPE
          </p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[84.67px] lg:h-[100px]" src={apache} alt="" />
          </div>
          <p className="text-sm lg:text-lg text-[#2C3131] font-bold mt-4 lg:mt-6">
            APACHE
          </p>
        </div>
        <div className="flex flex-col justify-center  w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[97px]" src={oracle} alt="" />
          </div>
          <p className="text-sm lg:text-lg text-[#2C3131] font-bold mt-4 lg:mt-6">
            ORACLE
          </p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[82.12px] lg:h-[97px]" src={xCode} alt="" />
          </div>
          <p className="text-sm lg:text-lg text-[#2C3131] font-bold mt-4 lg:mt-6">
            XCODE
          </p>
        </div>
        <div className="flex lg:hidden flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[51.64px] lg:h-[61px]" src={marvel} alt="" />
          </div>
          <p className="text-sm lg:text-lg text-[#2C3131] font-bold mt-4 lg:mt-6">
            MARVELAPP
          </p>
        </div>
        <div className="hidden lg:flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[61px]" src={marvel} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">MARVELAPP</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[63.49px] lg:h-[75px]" src={mySql} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">MYSQL</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[79.58px] lg:h-[94px]" src={laravel} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">LARAVEL</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[71.11px] lg:h-[94px]" src={figma} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">FIGMA</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[70.26px] lg:h-[94px]" src={kotlin} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">KOTLIN</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[61px]" src={firebase} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">FIREBASE</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[75px]" src={flutter} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">FLUTTER</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[94px]" src={java} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">JAVA</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[94px]" src={yiiFramework} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">YII FRAMEWORK</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[94px]" src={ionic} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">IONIC</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[84px]" src={react} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">REACT JS</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[129px]" src={mongo} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">MONGO DB</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[79px]" src={nodejs} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">NODE JS</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[84px]" src={swift} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">SWIFT</p>
        </div>
        <div className="flex flex-col justify-center w-[160px] lg:w-[189px] mt-10 lg:mt-0">
          <div className="h-[160px] lg:h-[189px] w-full bg-[#FFFFFF] rounded-full shadow-tech flex items-center justify-center">
            <img className="h-[61px]" src={mtcna} alt="" />
          </div>
          <p className="text-lg text-[#2C3131] font-bold mt-6">
            MIKROTIK MTCNA
          </p>
        </div>
      </div>
    </div>
  );
};
