import { AboutSection1 } from "../components/About/Section1";
import { AboutSection2 } from "../components/About/Section2";
import { AboutSection3 } from "../components/About/Section3";
import { Footer } from "../components/Footer";
import { HomeSection2 } from "../components/Home/Section2";
import { LetsStartProject } from "../components/LestStartProject";

export const About = () => {
  return (
    <div>
      <AboutSection1 />
      <AboutSection2 />
      <HomeSection2 />
      <AboutSection3 />
      <div className="-mb-48 w-full flex justify-center relative bg-[#FAFAFA]">
        <div className="w-[80vw] z-50">
          <LetsStartProject />
        </div>
      </div>
      <div className="z-10">
        <div className="w-full h-[250px] bg-[#091315] relative z-20"></div>
        <Footer />
      </div>
    </div>
  );
};
