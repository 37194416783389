import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import { Footer } from "../components/Footer";
import Modal from "../components/Modals/SuccessSubmit";

import mail from "../assets/icons/mail-black.svg";
import phone from "../assets/icons/phone-black.svg";
import locationImg from "../assets/icons/location-black.svg";

export const ContactUs = () => {
  const location = useLocation();
  const [tabActive, setTabActive] = useState(1);
  const [planSelected, setPlanSelected] = useState("small");
  const [showModal, setShowModal] = useState({
    visible: false,
    message: "Thanks for trusting us!",
  });

  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    aboutProject: "",
  });

  const [errorForm, setErrorForm] = useState({
    plan: false,
    name: false,
    email: false,
    phone: false,
    aboutProject: false,
  });
  const mailToOpen = () => {
    window.open("mailto:Wedianto@msi-digital.com");
  };

  const classTabActive =
    "cursor-pointer border-b-[1.5px] border-[#2C3131] w-full text-center font-bold text-[#2C3131] transition-all duration-500 pb-4 lg:pb-3";
  const classTabInactive =
    "cursor-pointer border-b-[1.5px] border-[#CACACA] w-full text-center font-bold text-[#A9A9A9] transition-all duration-500 pb-4 lg:pb-3";

  useEffect(() => {
    setPlanSelected("small");
  }, [tabActive]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    scroll.scrollToTop();
    const plan = params.get("plan");
    if (location.search === "?discussion") {
      setTabActive(2);
    } else {
      setTabActive(1);
    }
    if (plan) {
      setPlanSelected(plan);
    } else {
      setPlanSelected("small");
    }
  }, []);

  const emailBody = `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Document</title>
    </head>
    <body
      style="
        font-family: Roboto;
        font-style: normal;
        background-color: #f5f5f5;
        padding-top: 24px;
      "
    >
      <div
        style="
          width: 450px;
          height: fit-content;
          margin: auto;
          background: #ffffff;
          padding-bottom: 40px;
        "
      >
        <div
          style="
            text-align: center;
            margin-left: 40px;
            margin-right: 40px;
            padding-top: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid #e9e9e9;
          "
        >
          <img
            width="155px"
            src="https://ik.imagekit.io/gregorius/logo-msi_eRK2R587E.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677216583453"
            alt=""
            srcset=""
          />
        </div>
        <div style="padding-left: 40px; padding-right: 40px; padding-top: 24px">
          <p
            style="
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: -0.07px;
              color: #2c3131;
            "
          >
            Hi, Chris
          </p>
          <p
            style="
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: -0.07px;
              color: #a9a9a9;
            "
          >
            You have a <span style="color: #2c3131">${
              planSelected === "small" && tabActive === 1
                ? "Small Corporate Plan"
                : planSelected === "medium" && tabActive === 1
                ? "Medium Corporate Plan"
                : planSelected === "enterprise" && tabActive === 1
                ? "Enterprise Plan"
                : "Discussion"
            }</span> requested
            from the user :
          </p>
  
          <table style="background-color: #fafafa; width: 100%; padding: 16px">
            <tr>
              <td
                style="
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  letter-spacing: -0.07px;
                  color: #a9a9a9;
                "
              >
                Name
              </td>
              <td
                style="
                  text-align: right;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 14px;
                  letter-spacing: -0.07px;
                  color: #2c3131;
                "
              >
              ${input.name}
              </td>
            </tr>
            <tr>
              <td
                style="
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  letter-spacing: -0.07px;
                  color: #a9a9a9;
                "
              >
                Email
              </td>
              <td
                style="
                  text-align: right;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 14px;
                  letter-spacing: -0.07px;
                  text-decoration-line: underline;
                  color: #4f81bd;
                "
              >
              ${input.email}
              </td>
            </tr>
            <tr>
              <td
                style="
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  letter-spacing: -0.07px;
                  color: #a9a9a9;
                "
              >
                Phone Number
              </td>
              <td
                style="
                  text-align: right;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 14px;
                  letter-spacing: -0.07px;
                  text-decoration-line: underline;
                  color: #4f81bd;
                "
              >
              ${input.phone}
              </td>
            </tr>
          </table>
          <p
            style="
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: -0.07px;
              color: #a9a9a9;
            "
          >
            Message
          </p>
          <p
            style="
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: -0.07px;
              color: #2c3131;
            "
          >
           ${input.aboutProject}
          </p>
        </div>
      </div>
    </body>
  </html>`;

  const mailerConfig = {
    Host: "smtp.elasticemail.com",
    Port: "2525",
    Username: "mitrasinergiindonesia.digital@gmail.com",
    Password: "EFE864E5D8B2BB13FE8858B4A4ED84770F15",
    To: "Chris@msi-digital.com",
    From: "mitrasinergiindonesia.digital@gmail.com",
    Subject:
      planSelected === "small" && tabActive === 1
        ? "Small Plan Requested"
        : planSelected === "medium" && tabActive === 1
        ? "Medium Plan Requested"
        : planSelected === "enterprise" && tabActive === 1
        ? "Enterprise Plan Requested"
        : "Discussion Requested",
    Body: emailBody,
  };

  const submitForm = async () => {
    let error = {
      plan: false,
      name: false,
      email: false,
      phone: false,
    };
    if (!input.name) {
      error.name = true;
    } else {
      error.name = false;
    }
    if (!input.email) {
      error.email = true;
    } else {
      error.email = false;
    }
    if (!input.phone) {
      error.phone = true;
    } else {
      error.phone = false;
    }
    if (!input.aboutProject) {
      error.aboutProject = true;
    } else {
      error.aboutProject = false;
    }

    setErrorForm({ ...errorForm, ...error });

    if (!error.name && !error.email && !error.phone && !error.aboutProject) {
      window.Email &&
        window.Email.send(mailerConfig).then((response) => {
          setShowModal({
            visible: true,
            message:
              tabActive === 1
                ? "Thanks for trusting us!"
                : "Thanks for contact us!",
          });
        });
    }
  };

  return (
    <div>
      <div className="px-4 lg:px-20 py-20 lg:py-20">
        <div className="w-full lg:flex items-center justify-between gap-20">
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#FE0000] to-[#FF8100] font-bold text-xs lg:text-base">
              SAY HELLO
            </p>
            <p className="block lg:hidden text-[32px] leading-[40px] tracking-[-0.02em] font-semibold text-[#2C3131] mt-4 lg:mt-0">
              We’d Love to Hear from You. Let’s Get in Touch.
            </p>
            <p className="hidden lg:block lg:text-[48px] font-semibold text-[#2C3131] whitespace-nowrap">
              We’d Love to Hear from You.
            </p>
            <p className="hidden lg:block text-[48px] font-semibold text-[#2C3131] -mt-5">
              Let’s Get in Touch.
            </p>
          </div>
          <div className="w-full lg:w-1/2 pt-4 lg:pt-24 text-center lg:text-left">
            <p className="text-[#626262] text-sm lg:text-base">
              Interested in working together? Ok, don't be shy. Just fill out
              the form below.
            </p>
            <p className="text-[#626262] text-sm lg:text-base">
              Don't like forms? That's ok, just{" "}
              <span className="underline text-[#2C3131] font-medium">
                email us.
              </span>
            </p>
          </div>
        </div>
        <div className="w-full block lg:flex items-start mt-10 gap-20">
          <div className="w-full lg:w-1/2">
            <p className="w-full border-b-[1.5px] border-[#CACACA] text-white pb-4 lg:pb-3">
              XXX
            </p>
            <p className="mt-12 text-[#A9A9A9] font-semibold text-sm lg:text-base">
              OUR CONTACT
            </p>
            <div className="text-left">
              <div className="flex items-center mt-3 lg:mt-4 text-sm lg:text-base">
                <img className="h-4 w-4 lg:h-6 lg:w-6" src={mail} alt="" />
                <p
                  className="text-[#2C3131] font-medium ml-4 cursor-pointer"
                  onClick={mailToOpen}
                >
                  Wedianto@msi-digital.com
                </p>
              </div>
              <div className="flex items-center mt-3 lg:mt-4  text-sm lg:text-base">
                <img className="h-4 w-4 lg:h-6 lg:w-6" src={phone} alt="" />
                <p
                  className="text-[#2C3131] font-medium ml-4 cursor-pointer"
                  onClick={() => window.open(`https://wa.me/628561026209`)}
                >
                  (+62) 856-1026-209
                </p>
              </div>
              <div className="flex items-start mt-3 lg:mt-4 text-sm lg:text-base">
                <img
                  className="h-4 w-4 lg:h-6 lg:w-6"
                  src={locationImg}
                  alt=""
                />
                <div className="ml-4">
                  <p
                    className="text-[#2C3131] font-medium cursor-pointer"
                    onClick={() =>
                      window.open("https://goo.gl/maps/jFHBpBu6YZm1XHkx5")
                    }
                  >
                    Jl. Raya Kby. Lama No.42, RW.10, Grogol Sel.,
                  </p>
                  <p
                    className="text-[#2C3131] font-medium cursor-pointer"
                    onClick={() =>
                      window.open("https://goo.gl/maps/jFHBpBu6YZm1XHkx5")
                    }
                  >
                    Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota
                    Jakarta 12220
                  </p>
                </div>
              </div>
            </div>
            <Modal showModal={showModal} setShowModal={setShowModal} />
          </div>
          <div className="w-full lg:w-1/2 mt-12 lg:mt-0">
            <div className="w-full flex justify-between items-center">
              <div
                className={tabActive === 1 ? classTabActive : classTabInactive}
                onClick={() => setTabActive(1)}
              >
                <span>WORK WITH US</span>
              </div>
              <div
                className={tabActive === 2 ? classTabActive : classTabInactive}
                onClick={() => setTabActive(2)}
              >
                <span>DISCUSSION</span>
              </div>
            </div>
            <div>
              {tabActive === 1 && (
                <div>
                  <p className="font-bold mt-12">PLAN</p>
                  <div className="grid grid-cols-1 lg:grid-cols-3 mt-4 gap-3">
                    <div
                      className={
                        planSelected === "small"
                          ? "bg-[#2C3131] text-[#FFFFFF] py-4 pl-6 border-[1.5px] border-[#2C3131] cursor-pointer transition-all duration-500"
                          : "bg-[#FFFFFF] text-[#2C3131] py-4 pl-6 border-[1.5px] border-[#E9E9E9] cursor-pointer transition-all duration-500"
                      }
                      onClick={() => {
                        setPlanSelected("small");
                        setErrorForm({ ...errorForm, plan: false });
                      }}
                    >
                      <div className="flex lg:block items-center justify-between pr-4">
                        <div>
                          <p>Small Corporate</p>
                          <p className="my-1 font-semibold text-lg">
                            Rp. 50.000.000
                          </p>
                        </div>
                        <Link to={"/product"}>
                          <p className="text-[#FF8100] font-medium">
                            View Detail
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        planSelected === "medium"
                          ? "bg-[#2C3131] text-[#FFFFFF] py-4 pl-6 border-[1.5px] border-[#2C3131] cursor-pointer transition-all duration-500"
                          : "bg-[#FFFFFF] text-[#2C3131] py-4 pl-6 border-[1.5px] border-[#E9E9E9] cursor-pointer transition-all duration-500"
                      }
                      onClick={() => {
                        setPlanSelected("medium");
                        setErrorForm({ ...errorForm, plan: false });
                      }}
                    >
                      <div className="flex lg:block items-center justify-between pr-4">
                        <div>
                          <p>Medium Corporate</p>
                          <p className="my-1 font-semibold text-lg">
                            Rp. 80.000.000
                          </p>
                        </div>
                        <Link to={"/product"}>
                          <p className="text-[#FF8100] font-medium">
                            View Detail
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        planSelected === "enterprise"
                          ? "bg-[#2C3131] text-[#FFFFFF] py-4 pl-6 border-[1.5px] border-[#2C3131] cursor-pointer transition-all duration-500"
                          : "bg-[#FFFFFF] text-[#2C3131] py-4 pl-6 border-[1.5px] border-[#E9E9E9] cursor-pointer transition-all duration-500"
                      }
                      onClick={() => {
                        setPlanSelected("enterprise");
                        setErrorForm({ ...errorForm, plan: false });
                      }}
                    >
                      <div className="flex lg:block items-center justify-between pr-4">
                        <div>
                          <p>Enterprise</p>
                          <p className="my-1 font-semibold text-lg">
                            Rp. 100.000.000
                          </p>
                        </div>
                        <Link to={"/product"}>
                          <p className="text-[#FF8100] font-medium">
                            View Detail
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-10">
                {tabActive === 1 && (
                  <p className="font-bold ">MORE INFORMATION</p>
                )}
                <form action="">
                  <div className="flex items-center mt-4">
                    <input
                      className={`${
                        errorForm.name
                          ? "border-[#FE0000] placeholder:text-[#FE0000]"
                          : "border-[#E9E9E9]"
                      } peer w-full border-[1.5px] py-4 pl-6 focus:outline-none focus:border-[#2C3131]`}
                      placeholder={
                        errorForm.name ? "Please enter your name" : "Name"
                      }
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) => {
                        setInput({ ...input, name: e.target.value });
                        setErrorForm({ ...errorForm, name: false });
                      }}
                    />
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-[#A9A9A9] peer-focus:fill-[#2C3131] -ml-10"
                    >
                      <path d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z" />
                      <path d="M17.08 14.15C14.29 12.29 9.73996 12.29 6.92996 14.15C5.65996 15 4.95996 16.15 4.95996 17.38C4.95996 18.61 5.65996 19.75 6.91996 20.59C8.31996 21.53 10.16 22 12 22C13.84 22 15.68 21.53 17.08 20.59C18.34 19.74 19.04 18.6 19.04 17.36C19.03 16.13 18.34 14.99 17.08 14.15Z" />
                    </svg>
                  </div>

                  <div className="flex items-center mt-4">
                    <input
                      className={`${
                        errorForm.email
                          ? "border-[#FE0000] placeholder:text-[#FE0000]"
                          : "border-[#E9E9E9]"
                      } peer w-full border-[1.5px] py-4 pl-6 focus:outline-none focus:border-[#2C3131]`}
                      placeholder={
                        errorForm.email ? "Please enter your email" : "Email"
                      }
                      type="email"
                      name="email"
                      id="email"
                      onChange={(e) => {
                        setInput({ ...input, email: e.target.value });
                        setErrorForm({ ...errorForm, email: false });
                      }}
                    />
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-[#A9A9A9] peer-focus:fill-[#2C3131] -ml-10"
                    >
                      <path d="M15 0.5H5C2 0.5 0 2 0 5.5V12.5C0 16 2 17.5 5 17.5H15C18 17.5 20 16 20 12.5V5.5C20 2 18 0.5 15 0.5ZM15.47 6.59L12.34 9.09C11.68 9.62 10.84 9.88 10 9.88C9.16 9.88 8.31 9.62 7.66 9.09L4.53 6.59C4.21 6.33 4.16 5.85 4.41 5.53C4.67 5.21 5.14 5.15 5.46 5.41L8.59 7.91C9.35 8.52 10.64 8.52 11.4 7.91L14.53 5.41C14.85 5.15 15.33 5.2 15.58 5.53C15.84 5.85 15.79 6.33 15.47 6.59Z" />
                    </svg>
                  </div>

                  <div className="flex items-center mt-4">
                    <input
                      className={`${
                        errorForm.phone
                          ? "border-[#FE0000] placeholder:text-[#FE0000]"
                          : "border-[#E9E9E9]"
                      } peer w-full border-[1.5px] py-4 pl-6 focus:outline-none focus:border-[#2C3131]`}
                      placeholder={
                        errorForm.phone
                          ? "Please enter your phone number"
                          : "Phone Number"
                      }
                      type="text"
                      name="phone"
                      id="phone"
                      onChange={(e) => {
                        setInput({ ...input, phone: e.target.value });
                        setErrorForm({ ...errorForm, phone: false });
                      }}
                    />
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-[#A9A9A9] peer-focus:fill-[#2C3131] -ml-10"
                    >
                      <path d="M19.97 16.33C19.97 16.69 19.89 17.06 19.72 17.42C19.55 17.78 19.33 18.12 19.04 18.44C18.55 18.98 18.01 19.37 17.4 19.62C16.8 19.87 16.15 20 15.45 20C14.43 20 13.34 19.76 12.19 19.27C11.04 18.78 9.89 18.12 8.75 17.29C7.6 16.45 6.51 15.52 5.47 14.49C4.44 13.45 3.51 12.36 2.68 11.22C1.86 10.08 1.2 8.94 0.72 7.81C0.24 6.67 0 5.58 0 4.54C0 3.86 0.12 3.21 0.36 2.61C0.6 2 0.98 1.44 1.51 0.94C2.15 0.31 2.85 0 3.59 0C3.87 0 4.15 0.0600001 4.4 0.18C4.66 0.3 4.89 0.48 5.07 0.74L7.39 4.01C7.57 4.26 7.7 4.49 7.79 4.71C7.88 4.92 7.93 5.13 7.93 5.32C7.93 5.56 7.86 5.8 7.72 6.03C7.59 6.26 7.4 6.5 7.16 6.74L6.4 7.53C6.29 7.64 6.24 7.77 6.24 7.93C6.24 8.01 6.25 8.08 6.27 8.16C6.3 8.24 6.33 8.3 6.35 8.36C6.53 8.69 6.84 9.12 7.28 9.64C7.73 10.16 8.21 10.69 8.73 11.22C9.27 11.75 9.79 12.24 10.32 12.69C10.84 13.13 11.27 13.43 11.61 13.61C11.66 13.63 11.72 13.66 11.79 13.69C11.87 13.72 11.95 13.73 12.04 13.73C12.21 13.73 12.34 13.67 12.45 13.56L13.21 12.81C13.46 12.56 13.7 12.37 13.93 12.25C14.16 12.11 14.39 12.04 14.64 12.04C14.83 12.04 15.03 12.08 15.25 12.17C15.47 12.26 15.7 12.39 15.95 12.56L19.26 14.91C19.52 15.09 19.7 15.3 19.81 15.55C19.91 15.8 19.97 16.05 19.97 16.33Z" />
                    </svg>
                  </div>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="8"
                    className={`${
                      errorForm.aboutProject
                        ? "border-[#FE0000] placeholder:text-[#FE0000]"
                        : "border-[#E9E9E9]"
                    } peer w-full border-[1.5px] py-4 pl-6 focus:outline-none focus:border-[#2C3131] mt-4`}
                    placeholder={
                      errorForm.aboutProject
                        ? "Please tell us about your project"
                        : "Tell us about your project ..."
                    }
                    onChange={(e) => {
                      setInput({ ...input, aboutProject: e.target.value });
                      setErrorForm({ ...errorForm, aboutProject: false });
                    }}
                  />

                  <div className="flex items-center mt-6">
                    <input
                      className="border-[1.5px] border-[#A9A9A9] w-6 h-6 cursor-pointer accent-[#2C3131]"
                      type="checkbox"
                      name=""
                      id=""
                    />
                    <p className="font-medium text-[#2C3131] ml-4 text-sm lg:text-base">
                      I accept the{" "}
                      <span className="underline">Terms & Conditions</span>
                    </p>
                  </div>

                  <button
                    type="button"
                    className="bg-[#2C3131] text-white py-4 px-10 rounded-[30px] font-bold w-[240px] mt-12"
                    onClick={submitForm}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
