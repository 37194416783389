import { Footer } from "../components/Footer";
import { LetsStartProject } from "../components/LestStartProject";
import { ProductPlanSection1 } from "../components/ProductPlan/Section1";

export const ProductPlan = () => {
  return (
    <div>
      <ProductPlanSection1 />
      <div className="-mb-48 w-full flex justify-center z-20 relative">
        <div className="w-[80vw]">
          <LetsStartProject />
        </div>
      </div>
      <div className="z-10 ">
        <div className="w-full h-[250px] bg-[#091315] relative"></div>
        <Footer />
      </div>
    </div>
  );
};
