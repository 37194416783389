import { Link } from "react-router-dom";
import msiIconBlack from "../../assets/icons/msi-icon-black.svg";

export default function Modal({ showModal, setShowModal }) {
  return (
    <>
      {showModal.visible ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto transition-all duration-500 backdrop-blur-sm">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40 blur-3xl"></div>
            <div className="flex items-center min-h-screen">
              <div className="relative w-full lg:w-[38vw] mx-4 lg:mx-auto bg-white rounded-[24px] shadow-lg">
                <div className="flex items-center justify-between border-b-[1.5px] border-[#E9E9E9] py-4 px-6">
                  <div className="text-center w-full">
                    <p className="font-bold text-lg text-[#2C3131]">
                      Submit Successfully
                    </p>
                  </div>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                    onClick={() => setShowModal({ visible: false })}
                  >
                    <path
                      d="M1.00098 1L15 14.9991"
                      stroke="#2C3131"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M0.999964 14.9991L14.999 1"
                      stroke="#2C3131"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="mt-3 ">
                  <div className="w-full text-center">
                    <div className="w-full flex justify-center">
                      <img
                        className="h-20 lg:h-32"
                        src={msiIconBlack}
                        alt="icon-msi"
                      />
                    </div>
                    <div className="mt-10 px-6 lg:px-10">
                      <p className="text-[#2C3131] font-semibold text-lg lg:text-2xl">
                        {showModal.message}
                      </p>
                      <p className="block lg:hidden mt-2 font-medium text-[#626262] text-sm">
                        We have received your message! And we will quickly
                        respond to your message within{" "}
                        <span className="font-bold text-[#2C3131]">2x24</span>{" "}
                        hours by email.
                      </p>

                      <p className="hidden lg:block mt-2 font-medium text-[#626262]">
                        We have received your message! And we will quickly
                        respond
                      </p>
                      <p className="hidden lg:block font-medium text-[#626262]">
                        to your message within{" "}
                        <span className="font-bold text-[#2C3131]">2x24</span>{" "}
                        hours by email.
                      </p>
                    </div>
                    <div className="pb-10">
                      <Link to={"/"}>
                        <button
                          type="button"
                          className="bg-[#2C3131] text-white py-4 px-10 rounded-[30px] font-bold w-[240px] mt-10 lg:mt-12"
                        >
                          Okay
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
