import distributorXl from "../../assets/images/portofolio/distributor-xl.png";
import kasirHitsXl from "../../assets/images/portofolio/kasir-hits-xl.png";
import kosWifiNet1 from "../../assets/images/portofolio/kos-wifi-net1.png";
import loketNet1 from "../../assets/images/portofolio/loket-net1.png";
import mitrapejuangXl from "../../assets/images/portofolio/mitra-pejuang-xl.png";
import net1Idn from "../../assets/images/portofolio/net1-idn.png";
import splunk from "../../assets/images/portofolio/splunk.png";
import telkomIndonesia from "../../assets/images/portofolio/telkom-indonesia.png";
import wifiId from "../../assets/images/portofolio/wifi-id.png";

export const PortofolioSection1 = () => {
  return (
    <div className="px-4 lg:px-20 py-20 text-center">
      <h2 className="font-semibold text-[#2C3131] text-[32px] lg:text-[48px]">
        Our Portfolio
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 lg:gap-x-10 lg:gap-y-20 mt-16 place-items-center justify-items-center">
        <div className="text-left">
          <img className="lg:h-[400px]" src={telkomIndonesia} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4">
            MOBILE APPS <span className="dot"></span>{" "}
            <span>TELKOM INDONESIA</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            ODP Management Application
          </p>
        </div>
        <div className="text-left">
          <img className="lg:h-[400px]" src={wifiId} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4">
            WEBSITE <span className="dot"></span> <span>TELKOM INDONESIA</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            Telkom WIFI.ID
          </p>
        </div>
        <div className="text-left">
          <img className="lg:h-[400px]" src={net1Idn} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4 whitespace-nowrap">
            MOBILE APPS & DASHBOARD <span className="dot"></span>{" "}
            <span>NET1 INDONESIA</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            Distribution Management Application
          </p>
        </div>
        <div className="text-left">
          <img className="lg:h-[400px]" src={kosWifiNet1} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4">
            MOBILE APPS <span className="dot"></span>{" "}
            <span>NET1 INDONESIA</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            Loket NET1
          </p>
        </div>
        <div className="text-left">
          <img className="lg:h-[400px]" src={loketNet1} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4">
            MOBILE APPS <span className="dot"></span>{" "}
            <span>NET1 INDONESIA</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            Kios Wifi
          </p>
        </div>
        <div className="text-left">
          <img className="lg:h-[400px]" src={mitrapejuangXl} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4">
            MOBILE APPS <span className="dot"></span> <span>XL AXIATA</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            Mitra Pejuang SP
          </p>
        </div>
        <div className="text-left">
          <img className="lg:h-[400px]" src={kasirHitsXl} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4">
            MOBILE APPS <span className="dot"></span> <span>XL AXIATA</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            Kasir HITZ 2.0
          </p>
        </div>
        <div className="text-left">
          <img className="lg:h-[400px]" src={distributorXl} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4">
            DISTRIBUTOR <span className="dot"></span> <span>XL AXIATA</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            Authorized Distributor Maluku - Papua
          </p>
        </div>
        <div className="text-left">
          <img className="lg:h-[400px]" src={splunk} alt="" />
          <p className="text-sm lg:text-lg text-[#A9A9A9] font-medium mt-4 flex items-center gap-2 lg:gap-4">
            MOBILE APPS <span className="dot"></span> <span>NTT</span>
          </p>
          <p className="text-[#2C3131] text-lg lg:text-[24px] font-semibold mt-2">
            Splunk
          </p>
        </div>
      </div>
    </div>
  );
};
