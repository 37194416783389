import tickGreen from "../../assets/icons/tick-circle-green.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
import { Link, useNavigate } from "react-router-dom";

export const HomeSection5 = () => {
  const navigate = useNavigate();
  return (
    <div className="pt-20 h-fit pb-20">
      <div className="w-full flex justify-center">
        <h2
          className="font-bold text-[#FF8100] text-sm lg:text-base bg-FF8100-12 px-4 py-2 w-fit rounded-[20px]
      "
        >
          PRODUCT PLAN
        </h2>
      </div>
      <h3 className="#2C3131 font-semibold text-[28px] lg:text-[40px] text-center mt-4 lg:mt-0">
        What Can we Offer
      </h3>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 mt-10 px-4 lg:px-8">
        <div className="group col-span-1 py-5 border-[1.5px] border-[#E9E9E9] px-6 lg:px-10 hover:bg-[#FFFFFF] hover:shadow-card1 hover:border-none transition-all duration-100">
          <div className="flex justify-end w-full pt-5">
            <span className="bg-[#FF8100] text-white font-bold py-1 px-6 rounded-2xl">
              LOW
            </span>
          </div>
          <div className="text-left mt-12">
            <h5 className="font-semibold text-[#2C3131] text-base lg:text-2xl">
              Small Corporate
            </h5>
            <p className="text-[#2C3131] font-semibold text-[28px] lg:text-[40px] leading-[48px] tracking-[-0.03em] my-1 lg:my-2">
              Rp 50.000.000
            </p>
            <p className="text-[#A9A9A9] text-sm lg:text-lg font-medium ">
              Valid for 2 years
            </p>
            <hr className="border-b border-[#E9E9E9] my-4 lg:my-8" />
          </div>
          <div>
            <div className="flex">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">All You Can Develop</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">Resources</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">32 Manhours / Month</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">Rp 5.000.000 Monthly Fee</p>
            </div>
          </div>
          <button
            className="border border-[#2C3131] mt-12 w-full py-3 rounded-[30px] font-bold text-lg group-hover:bg-[#2C3131] group-hover:text-white transition-all duration-150"
            onClick={() => {
              navigate("/contact-us?plan=small");
            }}
          >
            Get Started
          </button>
          <Link to={"/product"}>
            <div className="flex mt-6 w-full justify-center">
              <p className="text-[#2C3131] font-semibold mr-1.5">Learn More</p>
              <img src={arrowRight} alt="" />
            </div>
          </Link>
        </div>
        <div className="group col-span-1 py-5 border-[1.5px] border-[#E9E9E9] px-6 lg:px-10 hover:bg-[#FFFFFF] hover:shadow-card1 hover:border-none transition-all duration-100">
          <div className="flex justify-end w-full pt-5">
            <span className="bg-[#4F81BD] text-white font-bold py-1 px-6 rounded-2xl">
              MID
            </span>
          </div>
          <div className="text-left mt-12">
            <h5 className="font-semibold text-[#2C3131] text-base lg:text-2xl">
              Medium Corporate
            </h5>
            <p className="text-[#2C3131] font-semibold text-[28px] lg:text-[40px] leading-[48px] tracking-[-0.03em] my-1 lg:my-2">
              Rp 80.000.000
            </p>
            <p className="text-[#A9A9A9] font-medium text-sm lg:text-lg">
              Valid for 2 years
            </p>
            <hr className="border-b border-[#E9E9E9] my-8" />
          </div>
          <div>
            <div className="flex">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">All You Can Develop</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">Resources</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">48 Manhours / Month</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">Rp 8.000.000 Monthly Fee</p>
            </div>
          </div>
          <button
            className="border border-[#2C3131] mt-12 w-full py-3 rounded-[30px] font-bold text-lg group-hover:bg-[#2C3131] group-hover:text-white transition-all duration-150"
            onClick={() => {
              navigate("/contact-us?plan=medium");
            }}
          >
            Get Started
          </button>
          <Link to={"/product"}>
            <div className="flex mt-6 w-full justify-center">
              <p className="text-[#2C3131] font-semibold mr-1.5">Learn More</p>
              <img src={arrowRight} alt="" />
            </div>
          </Link>
        </div>
        <div className="group col-span-1 py-5 border-[1.5px] border-[#E9E9E9] px-6 lg:px-10 hover:bg-[#FFFFFF] hover:shadow-card1 hover:border-none transition-all duration-100">
          <div className="flex justify-end w-full pt-5">
            <span className="bg-[#FE0000] text-white font-bold py-1 px-6 rounded-2xl">
              HIGH
            </span>
          </div>
          <div className="text-left mt-12">
            <h5 className="font-semibold text-[#2C3131] text-base lg:text-2xl">
              Enterprise
            </h5>
            <p className="text-[#2C3131] font-semibold text-[28px] lg:text-[40px] leading-[48px] tracking-[-0.03em] my-1 lg:my-2">
              Rp 150.000.000
            </p>
            <p className="text-[#A9A9A9] text-sm lg:text-lg font-medium ">
              Valid for 3 years
            </p>
            <hr className="border-b border-[#E9E9E9] my-8" />
          </div>
          <div>
            <div className="flex">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">All You Can Develop</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">Resources</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">64 Manhours / Month</p>
            </div>
            <div className="flex mt-6">
              <img src={tickGreen} alt="" />
              <p className="ml-3.5 text-[#2C3131]">Rp 15.000.000 Monthly Fee</p>
            </div>
          </div>
          <button
            className="border border-[#2C3131] mt-12 w-full py-3 rounded-[30px] font-bold text-lg group-hover:bg-[#2C3131] group-hover:text-white transition-all duration-150"
            onClick={() => {
              navigate("/contact-us?plan=enterprise");
            }}
          >
            Get Started
          </button>
          <Link to={"/product"}>
            <div className="flex mt-6 w-full justify-center">
              <p className="text-[#2C3131] font-semibold mr-1.5">Learn More</p>
              <img src={arrowRight} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
