import visionMission from "../../assets/images/vision-mission.png";
import visionMissionMobile from "../../assets/images/vision-mision-mobile.png";

export const AboutSection2 = () => {
  return (
    <div className="h-fit pt-20 text-center px-4 lg:px-20">
      <p className="font-bold text-[28px] lg:text-[40px] text-[#2C3131] px-0 lg:px-40 tracking-[-0.03em]">
        To become Indonesia's leading partner for digital solution and
        aggregators through its access, technology and analytics
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-10 lg:mt-16">
        <div className="border-[1.5px] border-[#E9E9E9] p-6 text-left">
          <p className="text-[#626262] font-medium">
            <span className="font-bold text-[#2C3131]">Platform Solution:</span>{" "}
            become the top of mind digital platform enabler for
            Telecommunication Provider by providing and developing best-in-class
            solution in Indonesia, including platform integrator in driving the
            business model of BOM (Build-Operate-Manage)
          </p>
        </div>
        <div className="border-[1.5px] border-[#E9E9E9] p-6 text-left">
          <p className="text-[#626262] font-medium">
            <span className="font-bold text-[#2C3131]">PPOB (last-mile): </span>{" "}
            become the preferred method of payment point through mobile apps for
            Indonesia offline store (SME) with emphasis on micro transaction
            including Wi-Fi, digital goods, and rewards.
          </p>
        </div>
        <div className="border-[1.5px] border-[#E9E9E9] p-6 text-left">
          <p className="text-[#626262] font-medium">
            <span className="font-bold text-[#2C3131]">
              Advertising Creative:
            </span>{" "}
            become the alternative of offline and online advertising channel by
            providing creative one stop ads services for both local &
            Multinational advertiser in Indonesia. by providing analytics
            capability as a competitive advantage
          </p>
        </div>
      </div>
      <div className="flex justify-center mt-20 lg:pb-20">
        <img
          className="h-[412px] hidden lg:block pl-28"
          src={visionMission}
          alt=""
        />
        <img
          className="w-[90vw] block lg:hidden"
          src={visionMissionMobile}
          alt=""
        />
      </div>
    </div>
  );
};
