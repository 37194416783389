import React, { useEffect } from "react";

import {
  BrowserRouter,
  MemoryRouter,
  Navigate,
  Route,
  Router,
  Routes,
  Switch,
  useLocation,
} from "react-router-dom";

import { Home } from "./pages/Home";

import "./App.css";
import { animateScroll as scroll } from "react-scroll";
import { About } from "./pages/About";
import { Service } from "./pages/Service";
import { ProductPlan } from "./pages/ProductPlan";
import { Portofolio } from "./pages/Portofolio";
import { ContactUs } from "./pages/ContactUs";

import { Header } from "./components/Header";

function App() {
  const location = useLocation();
  useEffect(() => {
    const params = location.search;
    if (!params) {
      scroll.scrollToTop();
    }
  }, [location]);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/product" element={<ProductPlan />} />
        <Route path="/portofolio" element={<Portofolio />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
}

export default App;
