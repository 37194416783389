import plusIcon from "../../assets/icons/plus.svg";
import minusIcon from "../../assets/icons/minus.svg";

import { Collapse } from "react-collapse";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const AboutSection3 = () => {
  const navigate = useNavigate();
  const [faqOpen1, setFaqOpen1] = useState(false);
  const [faqOpen2, setFaqOpen2] = useState(false);
  const [faqOpen3, setFaqOpen3] = useState(false);
  const [faqOpen4, setFaqOpen4] = useState(false);
  const [faqOpen5, setFaqOpen5] = useState(false);
  const [faqOpen6, setFaqOpen6] = useState(false);
  const [faqOpen7, setFaqOpen7] = useState(false);
  const [faqOpen8, setFaqOpen8] = useState(false);
  const [faqOpen9, setFaqOpen9] = useState(false);
  const [faqOpen10, setFaqOpen10] = useState(false);

  const moveContactUs = () => {
    navigate("/contact-us?discussion");
  };

  return (
    <div className="text-left bg-[#FAFAFA] px-4 lg:px-20 py-20">
      <div className="block lg:flex justify-between">
        <div>
          <h2 className="font-bold text-[#2C3131] text-[40px]">FAQs</h2>
          <p className="text-[#626262] text-lg">Frequently Asked Question</p>
        </div>
        <div className="flex items-center gap-6 mt-10 lg:mt-0">
          <h2 className="font-medium text-[#626262] text-sm lg:text-base">
            Still have a question?
          </h2>
          <button
            className="bg-[#2C3131] text-white py-3 lg:py-4 px-10 rounded-[28px] font-bold whitespace-nowrap"
            onClick={moveContactUs}
          >
            Contact Us
          </button>
        </div>
      </div>
      <div className="block lg:flex items-start gap-x-10 mt-6 lg:mt-20">
        <div className="w-full lg:w-1/2">
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 flex items-start justify-between transition-all duration-500 h-fit">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen1(!faqOpen1)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131] text-sm lg:text-base">
                    How do you usually start new collaborations?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen1 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen1}>
                <p className="text-[#626262] mt-4 text-sm lg:text-base">
                  Each new engagement starts with an introduction call and
                  filling out the project brief. If you have a cool project in
                  mind, feel free to get in touch at{" "}
                  <span
                    className="font-semibold text-[#FF8100] underline cursor-pointer"
                    onClick={() =>
                      window.open("mailto:Wedianto@msi-digital.com")
                    }
                  >
                    Wedianto@msi-digital.com
                  </span>
                </p>
              </Collapse>
            </div>
          </div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 flex items-start justify-between transition-all duration-500 h-fit mt-4">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen2(!faqOpen2)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131] text-sm lg:text-base">
                    How long does it take to get started?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen2 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen2}>
                <p className="text-[#626262] mt-4 text-sm lg:text-base">
                  Choose your plan with us in the{" "}
                  <Link to={"/product"}>
                    <span className="font-semibold text-[#FF8100] underline">
                      Product Plan
                    </span>
                  </Link>
                  . Just two days since signing and we are there with initial
                  meetings to kick it off.
                </p>
              </Collapse>
            </div>
          </div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 flex items-start justify-between transition-all duration-500 h-fit mt-4">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen3(!faqOpen3)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131] text-sm lg:text-base">
                    What is your typical working process?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen3 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen3}>
                <p className="text-[#626262] mt-4 text-sm lg:text-base">
                  We always start a project with analysis, learning the
                  requirements, and defining the work. Then it’s off to signing
                  off, kicking off, and getting your business to the next level
                  with a pinch of our work magic.
                </p>
              </Collapse>
            </div>
          </div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 text-sm lg:text-base flex items-start justify-between transition-all duration-500 h-fit mt-4">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen4(!faqOpen4)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131]">
                    Do you work on a fixed-price basis or time and material?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen4 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen4}>
                <p className="text-[#626262] mt-4">
                  We try to suggest an appropriate solution for each specific
                  case we deal with. The more clear and detailed the
                  requirements are, the more accurate will our estimates be.
                  This is the closest we get to the fixed-price model in our{" "}
                  <Link to={"/product"}>
                    <span className="font-semibold text-[#FF8100] underline">
                      Product Plan
                    </span>
                  </Link>
                </p>
              </Collapse>
            </div>
          </div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 text-sm lg:text-base flex items-start justify-between transition-all duration-500 h-fit mt-4 relative">
            <div className="absolute top-3 right-0 transition-transform duration-1000 w-12 h-12">
              <div className="w-full h-full flex items-center">
                <img
                  className="object-cover"
                  src={faqOpen5 ? minusIcon : plusIcon}
                  alt=""
                />
              </div>
            </div>
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen5(!faqOpen5)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131] pr-10">
                    Do you guys work with big companies only? We’re an
                    early-stage startup.
                  </p>
                </div>
              </div>
              <Collapse isOpened={faqOpen5}>
                <p className="text-[#626262] mt-4 pr-10">
                  We’re all for building a borderless world, so we are open to
                  working with any project we come across.
                </p>
              </Collapse>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 mt-4 lg:mt-0">
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 text-sm lg:text-base flex items-start justify-between transition-all duration-500 h-fit">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen6(!faqOpen6)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131]">
                    How long it will take to get an estimate from you?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen6 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen6}>
                <p className="text-[#626262] mt-4">
                  We hate to keep you waiting. After you choose the plan and
                  signing, We start setting up a team, making preliminary
                  research and analysis, and getting back to you with the
                  concept takes up to 3x24 hours from the moment we learn what’s
                  needed. Efficiency is key values.
                </p>
              </Collapse>
            </div>
          </div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 text-sm lg:text-base flex items-start justify-between transition-all duration-500 h-fit mt-4">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen7(!faqOpen7)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131]">
                    What’s the average project duration?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen7 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen7}>
                <p className="text-[#626262] mt-4">
                  Our team works full-time on every project, and the duration
                  will depend on the product plan you choose.
                </p>
              </Collapse>
            </div>
          </div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 text-sm lg:text-base flex items-start justify-between transition-all duration-500 h-fit mt-4">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen8(!faqOpen8)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131]">
                    Can we start the design without mockup?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen8 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen8}>
                <p className="text-[#626262] mt-4">
                  Mockup help to build UX without distractions. The mockup is an
                  essential part of the design process and we would not
                  recommend eliminating it. Having everything polished at the
                  start stage, we can avoid many amendments and revisions at the
                  design stage, which saves both time.
                </p>
              </Collapse>
            </div>
          </div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 text-sm lg:text-base flex items-start justify-between transition-all duration-500 h-fit mt-4">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen9(!faqOpen9)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131]">
                    What templates does MSI Suite use to create design?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen9 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen9}>
                <p className="text-[#626262] mt-4">
                  We don’t use pre-built templates or kits because there’s just
                  no soul in them. We’re committed to building fully customized
                  and product-based solutions that will meet the expectations of
                  the target users. Each pixel and line is designed with love
                  and passion by our creative minds.
                </p>
              </Collapse>
            </div>
          </div>
          <div className="bg-[#FFFFFF] border-[1.5px] border-[#E9E9E9] p-4 lg:p-6 text-sm lg:text-base flex items-start justify-between transition-all duration-500 h-fit mt-4">
            <div className="w-full">
              <div
                className="w-full flex items-center justify-between cursor-pointer relative"
                onClick={() => setFaqOpen10(!faqOpen10)}
              >
                <div>
                  <p className="font-semibold text-[#2C3131]">
                    How do we assure privacy and confidentiality?
                  </p>
                </div>

                <div className="flex justify-end items-center transition-transform duration-1000">
                  <img
                    className="object-cover"
                    src={faqOpen10 ? minusIcon : plusIcon}
                    alt=""
                  />
                </div>
              </div>
              <Collapse isOpened={faqOpen10}>
                <p className="text-[#626262] mt-4">
                  This clause is covered in the NDA and Confidentiality
                  Agreements is also signed between MSI Suite and the customers
                  that specifying the same.
                </p>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
