import { Link } from "react-router-dom";

export const LetsStartProject = () => {
  return (
    <div className=" bg-[#FF8100] h-96 text-[#FFFFFF] text-center pt-16 lg:pt-20 px-4 lg:px-8 w-full">
      <p className="text-[28px] lg:text-5xl font-semibold tracking-[-0.04em]">
        Let's Start a New Project Together
      </p>
      <p className="text-sm lg:text-xl pt-3 px-0 lg:px-48">
        Get in touch and let's see how we can help you move things forward.
      </p>
      <Link to={"/contact-us?discussion"}>
        <button className="bg-[#FFFFFF] text-[#2C3131] font-bold px-10 py-4 rounded-[30px] mt-8">
          GET IN TOUCH
        </button>
      </Link>
    </div>
  );
};
